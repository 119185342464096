<template>
  <div class="container_about">
    <div class="pa-5">
      <div class="d-flex pa-2 mb-3 align-center">
        <blue-circle class="mr-3" />
        <h3 class="color_default">Konfigurasi Formulir</h3>
      </div>
      <v-card class="rounded-max custom_card pa-3">
        <v-tabs v-model="tab" background-color="transparent" hide-slider>
          <v-tab active-class="active_tab" href="#tab-1" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Kesling Kunci</p>
            </div>
          </v-tab>
          <v-tab active-class="active_tab" href="#tab-2" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Kelola Limbah</p>
            </div>
          </v-tab>
          <v-tab active-class="active_tab" href="#tab-3" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Kesling Plus</p>
            </div>
          </v-tab>
           <v-tab active-class="active_tab" href="#tab-4" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Sanitarian Kit</p>
            </div>
          </v-tab>
          <v-tab active-class="active_tab" href="#tab-5" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Limbah Covid-19</p>
            </div>
          </v-tab>
          <v-tab active-class="active_tab" href="#tab-6" class="color_txt mr-2">
            <div class="text-capitalize">
              <p class="pa-2 ma-2 font-weight-bold">Alkes Bermerkuri</p>
            </div>
          </v-tab>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab" style="background: none">
         <v-tab-item value="tab-1">
          <kunci-conf />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <fasyankes-conf/>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <kesling-conf />
        </v-tab-item>
        <v-tab-item value="tab-4">
          <sanitarian-kit-conf/>
        </v-tab-item>
         <v-tab-item value="tab-5">
          <limbah-covid-19-conf />
        </v-tab-item>
        <v-tab-item value="tab-6">
          <alkes-merkuri-conf/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import BlueCircle from "../../../components/General/BlueCircle";
import AlkesMerkuriConf from './alkesMerkuriConf.vue';
import FasyankesConf from './FasyankesConf.vue';
import KeslingConf from "./keslingConf.vue";
import KunciConf from "./KunciConf.vue";
import LimbahCovid19Conf from "./limbahCovid19Conf.vue";
import SanitarianKitConf from './sanitarianKitConf.vue';
export default {
  name: "formulirConf",
  components: { BlueCircle, LimbahCovid19Conf, KunciConf, KeslingConf, SanitarianKitConf, AlkesMerkuriConf, FasyankesConf },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style scoped>
.v-tabs-slider {
  background: none !important;
}
.active_tab {
  background: #00b4cc;
  border-radius: 8px;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
  color: #fff;
}
</style>