<template>
  <div style="margin-top: 30px">
    <v-card class="radius-card pa-5" flat>
      <v-card class="radius-card pa-3" outlined>
        <div>
          <b>Upload Data Borang</b>
          <v-row>
            <v-col cols="7">
              <v-card class="d-flex align-center pa-2" outlined>
                <div class="mr-3">
                  <p class="ma-0 small_txt color_txt">file xlsx</p>
                </div>
                <input type="file" @change="selectedFile($event)" />
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-select :items="getYear" dense outlined></v-select>
            </v-col>
          </v-row>
        </div>
        <p class="small_txt red--text">
          <i>*Data yang diupload memiliki format.xlsx</i>
        </p>
        <div>
          <b>Link Borang</b>
          <v-row>
            <v-col cols="7">
              <input type="text" class="pa-2 custom_long_input" />
            </v-col>
            <v-col cols="3"> </v-col>
          </v-row>
        </div>
      </v-card>
      <div class="d-flex justify-end mt-5">
        <v-btn
          class="text-capitalize"
          v-if="!loading"
          rounded
          dark
          @click="postFile"
          color="#00b4cc"
          >Submit</v-btn
        >
        <v-btn
          class="text-capitalize"
          v-if="loading"
          rounded
          dark
          color="#00b4cc"
          >Loaidng...</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "alkesBermerkuri",
  computed: {
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2020; i <= year; i++) {
        data.push(i);
      }
      return data;
    },
  },
  data() {
    return {
      file: null,
      year: this.$date().format("YYYY"),
      loading: false,
    };
  },
  methods: {
    selectedFile(e) {
      this.file = e.target.files[0];
    },
    postFile() {
      this.loading = true;
      let data = new FormData();
      data.append("file", this.file);
      this.$store
        .dispatch("formulir/updateFileMerkuri", data)
        .then((data) => {
          console.log(data);
          this.Swal("success", "Data Berhasil Dikirim");
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.Swal("error", "Data Gagal Dikirim");
          this.loading = false;
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom_long_input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}
</style>