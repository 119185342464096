<template>
  <div class="bg_main full_h pb-5">
    <div class="d-flex justify-center">
      <div style="margin-top: 50px; width: 100%" class="pb-5">
        <div class="temp_itemtab">
          <v-stepper
            v-model="step"
            elevation="0"
            flat
            class="temp_stepper_header"
          >
            <div class="d-flex" style="overflow-x: auto">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Udara
                </p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Pangan
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengukuran Kualitas Air
                </p>
              </div>
              <div
                :class="
                  step == 4
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Photometer Kimia
                </p>
              </div>
              <div
                :class="
                  step == 5
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 5 ? 'number_active' : 'number_unactive'">
                  5
                </div>
                <p class="ma-0 small_txt text-center">
                  Peralatan Pendukung Pengukuran Mikrobiologi
                </p>
              </div>
              <div
                :class="
                  step == 6
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 6 ? 'number_active' : 'number_unactive'">
                  6
                </div>
                <p class="ma-0 small_txt text-center">Peralatan Pendukung</p>
              </div>
            </div>

            <v-stepper-items>
              <!-- TAB 1 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="1" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Kualitas Udara</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Suhu
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group row dense disabled hide-details>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Kelembaban
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group row disabled dense hide-details>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur kecepatan aliran udara
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            disabled
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur pencahayaan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            disabled
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Partikulat diudara
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Kebisingan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur Jumlah kuman
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 2 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="2" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk pengukuran Parameter Kimia</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Methanyl Yellow
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Rodhamin B</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group disabled row dense class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Formaldehid</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Boraks</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Reagen untuk Parameter Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">E-Coli</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coliform</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Alat Pengukur suhu
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 3 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="3" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Warna (Colorimeter)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Total Zat padat terlarut (TDS)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Kekeruhan (Turbiditymeter)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk Pengukuran Parameter Kimia</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Arsen</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Flourida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrit (NO2)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrat (NO3)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Nitrat (NO3)</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sianida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Alumunium</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled dense class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Besi</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Kesadahan</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Klorida</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Mangan</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              pH meter (digital)
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Seng</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sulfat</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Tembaga</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Amonia</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Sisa Klor</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Total krom</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b
                        >Ketersediaan Reagen untuk pengukuran Parameter
                        Mikrobiologi</b
                      >
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">E-Coli</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group row disabled hide-details class="ma-0">
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="8">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coliform</p>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Tersedia"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Tersedia"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
              <!-- TAB 4 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="4" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Photometer Kimia</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Photometer Kimia
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- Tab 5 -->
              <v-stepper-content step="5" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Inkubator</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Colony counter digital
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- Tab 6 -->
              <v-stepper-content step="6" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pencacah sampel/blender
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Mortar dan pestel
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Global Positioning System
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Coolbox</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pinset dan gunting
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Pipet transfer/spuit
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Salin steril</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            disabled
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Timbangan digital
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Aquades</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Wadah sampel</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Masker</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Kertas saring
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Alcohol swab</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Corong kaca</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Rak tabung</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Lampu Spirtus
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Sarung Tangan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Botol Sampel</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Tas Peralatan
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="7">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">Spatula</p>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <v-radio-group
                            row
                            dense
                            disabled
                            hide-details
                            class="ma-0 panjang-pilihan"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Berfungsi"
                              value="2"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Ada - Tidak Berfungsi"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center">
            <v-btn
              color="white"
              dark
              class="pa-3 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('prev')"
              ><v-icon small color="#00b4cc"
                >mdi-arrow-left-circle-outline</v-icon
              >
              <p class="ma-0 color_default ml-2">Kembali</p></v-btn
            >
            <div class="mx-5">
              <p class="ma-0 color_default">{{ step }}/6</p>
            </div>
            <v-btn
              v-if="step != 6"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('next')"
            >
              <p class="ma-0 mr-2">Lanjut</p>
              <v-icon small>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="step == 6"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
            >
              <p class="ma-0 mr-2">lanjut</p>
              <v-icon small>mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  computed: {
    ...mapState({
      formulir_sanitarian: (state) => state.formulir.get_formulir_sanitarian,
    }),
  },
  name: "formulirLimbahcoivd",
  data() {
    return {
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
    };
  },

  methods: {
    switchStep(state) {
      if (state == "next") {
        if (this.step < 6) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // update data

    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 312px;
  min-width: 300px;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 312px;
  min-width: 300px;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>