<template>
  <!-- FORMULIR  KESEHATAN LINGKUNGAN -->
  <div class="bg_main full_h pb-5">
    <div class="side_toolbar"></div>
    <div class="d-flex justify-center">
      <div style="margin-top: 30px" class="pb-5">
        <v-stepper
          v-model="step"
          elevation="0"
          flat
          class="temp_stepper_header"
        >
          <div class="d-flex">
            <div
              :class="
                step == 1
                  ? 'active_tab px-3 py-5 mr-1'
                  : step > 4
                  ? 'mini_tab px-3 py-5'
                  : 'header_tabs px-3 py-5 mr-1'
              "
            >
              <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                1
              </div>
              <p class="ma-0 small_txt text-center" v-if="step < 4">Air</p>
            </div>
            <div
              :class="
                step == 2
                  ? 'active_tab px-3 py-5 mx-1'
                  : step > 5
                  ? 'mini_tab px-3 py-5'
                  : 'header_tabs px-3 py-5 mx-1'
              "
            >
              <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                2
              </div>
              <p class="ma-0 small_txt text-center" v-if="step < 5">Sanitasi</p>
            </div>
            <div
              :class="
                step == 3
                  ? 'active_tab px-3 py-5 mx-1'
                  : step > 6
                  ? 'mini_tab px-3 py-5'
                  : 'header_tabs px-3 py-5 mx-1'
              "
            >
              <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                3
              </div>
              <p class="ma-0 small_txt text-center" v-if="step < 6">
                Pengelolaan Limbah medis
              </p>
            </div>
            <div
              :class="
                step == 4
                  ? 'active_tab px-3 py-5 mx-1'
                  : 'header_tabs px-3 py-5 mx-1'
              "
            >
              <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                4
              </div>
              <p class="ma-0 small_txt text-center">Kebersihan Tangan</p>
            </div>
            <div
              :class="
                step == 5
                  ? 'active_tab px-3 py-5 mx-1'
                  : step > 5
                  ? 'header_tabs px-3 py-5 mx-1'
                  : 'mini_tab px-3 py-5'
              "
            >
              <div :class="step == 5 ? 'number_active' : 'number_unactive'">
                5
              </div>
              <p class="ma-0 small_txt text-center" v-if="step > 4">
                Kebersihan Lingkungan
              </p>
            </div>
            <div
              :class="
                step == 6
                  ? 'active_tab px-3 py-5 mx-1'
                  : step > 6
                  ? 'header_tabs px-3 py-5 mx-1'
                  : 'mini_tab px-3 py-5'
              "
            >
              <div :class="step == 6 ? 'number_active' : 'number_unactive'">
                6
              </div>
              <p class="ma-0 small_txt text-center" v-if="step > 5">
                Energi dan Lingkungan
              </p>
            </div>
            <div
              :class="
                step == 7
                  ? 'active_tab px-3 py-5 ml-1'
                  : step > 6
                  ? 'header_tabs px-3 py-5 ml-1'
                  : 'mini_tab px-3 py-5'
              "
            >
              <div :class="step == 7 ? 'number_active' : 'number_unactive'">
                7
              </div>
              <p class="ma-0 small_txt text-center" v-if="step > 6">
                Manajemen dan tenaga Kerja
              </p>
            </div>
          </div>
          <v-stepper-items>
            <!-- ========================================STEP 1 ============================================= -->
            <v-stepper-content step="1" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Pasokan Air</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                    <b>Pasokan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Pasokan air dari sumber yang layak disalurkan ke Fasyankes
                      atau terletak di tempat
                    </p>
                    <v-row>
                      <v-tooltip right v-model="showTooltip[0]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Peningkatan pasokan air didefinisikan oleh Program
                          Pemantauan Bersama WHO/UNICEF yang berdasarkan sifat
                          desain dan konstruksinya memiliki potensi untuk
                          menghasilkan air yang aman. Contohnya meliputi: air
                          perpipaan, keran umum; sumur gali yang terlindungi;
                          sumur tabung; atau lubang bor, air hujan dan air
                          kemasan atau yang dikirim. Baik hijau atau kuning akan
                          dihitung sebagai layanan air dasar, jika air juga
                          tersedia, yaitu W_3b kuning atau hijau).</span
                        >
                      </v-tooltip>
                    </v-row>
                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Pasokan air dari sumber yang layak dapat diakses di tempat (di dalam gedung Fasyakes)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Pasokan air dari sumber yang layak dapat diakses di tempat (tetapi di luar gedung Fasyankes)"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sumber air dari sumber yang layak yang dapat diakses di lokasi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                    <b>Pasokan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyankes ini memiliki persediaan air perpipaan di lokasi
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[1]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Untuk rumah sakit perawatan sekunder/tersier, air
                          perlu disalurkan ke Fasyankes, minimal ke bangsal dan
                          area layanan berisiko tinggi (misalnya, area
                          dekontaminasi/pemrosesan ulang dan area layanan
                          lingkungan). <br />
                          <br />
                          Di Fasyankes besar, harus ada keran fungsional yang
                          tersedia di area di setiap lantai dan setiap bangsal
                          utama atau sayap Fasyankes, untuk tujuan pembersihan
                          lingkungan. <br />
                          <br />
                          Untuk Fasyankes primer, baik hijau atau kuning akan
                          dihitung sebagai layanan air dasar tetapi untuk
                          Fasyankes sekunder atau tersier yang telah
                          meningkatkan air, tetapi tidak disalurkan, akan
                          berwarna merah. Meskipun ini dihitung sebagai layanan
                          air dasar untuk tujuan pemantauan JMP, Fasyankes
                          sekunder atau lebih tinggi tanpa air perpipaan tidak
                          dianggap memenuhi persyaratan tingkat layanan
                          minimum</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air disalurkan ke dalam Fasyankes ke semua bangsal berisiko tinggi (bersalin, ruang operasi / OR, perawatan intensif / ICU)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air disalurkan ke dalam tetapi tidak ke semua bangsal berisiko tinggi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pasokan air perpipaan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Pasokan Air / Leding</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                    <b>Pasokan Air / Leding</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua keran terhubung ke pasokan air yang tersedia dan
                      berfungsi, tanpa kebocoran pada pipa
                    </p>
                    <v-row>
                      <v-tooltip right v-model="showTooltip[2]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pipa air harus diperiksa secara teratur dan sistem di
                          tempat untuk memperbaiki kebocoran segera setelah
                          ditemukan.<br /><br />
                          Di Fasyankes besar, harus ada keran fungsional yang
                          tersedia di area konsultasi di setiap lantai dan
                          setiap bangsal utama atau sayap Fasyankes, untuk
                          tujuan pembersihan lingkungan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua keran terhubung dan berfungsi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Lebih dari setengah dari semua keran terhubung dan berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kurang dari setengah dari semua ketukan terhubung dan berfungsi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Ketersediaan Air</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan air</b>
                    <p class="ma-0 color_txt small_txt">
                      Air tersedia selama jam pelayanan Fasyankes
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[3]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Air harus tersedia di Fasyankes untuk semua hari /
                          jam buka dan memberikan perawatan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia 7 hari seminggu, setiap hari dan sepanjang hari"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia 4 hari/minggu dan/atau tidak sepanjang hari"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia kurang dari 4 hari per minggu dan/atau tidak tersedia selama lebih dari setengah hari"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Air tersedia pada saat penilaian WASH FIT dilakukan
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[4]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pertanyaan ini sejalan dengan pertanyaan yang
                          direkomendasikan JMP "Apakah air tersedia dari pasokan
                          air utama
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia di seluruh Fasyankes"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia dari beberapa tetapi tidak semua titik air"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada air yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Air tersedia sepanjang tahun (yaitu tidak terpengaruh oleh
                      musim, variabilitas cuaca/peristiwa ekstrem atau kendala
                      lainnya)
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air tersedia sepanjang tahun"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kekurangan air selama satu hingga dua bulan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kekurangan air selama tiga bulan atau lebih"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Sistem pasokan air utama berfungsi selama 3 bulan terakhir
                      tanpa kerusakan besar
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[6]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Kerusakan adalah tidak ada pengiriman air ATAU sistem
                          memberikan kurang dari 50% dari hasil air desain.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Dalam 3 bulan terakhir sistem pasokan air utama tidak mengalami kerusakan atau ada kerusakan tetapi diperbaiki dalam waktu 48 jam."
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem pasokan air mengalami kerusakan tetapi diperbaiki dalam waktu satu minggu."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem pasokan air mengalami kerusakan yang membutuhkan waktu lebih dari satu minggu untuk memperbaiki ATAU tetap tidak diperbaiki"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Sumber air layak tambahan diidentifikasi, tersedia, dan
                      dapat diakses (dan pelihara secara memadai) jika sumber
                      utama tidak berfungsi/tersedia
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[7]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pengolahan air mungkin diperlukan untuk persediaan
                          cadangan karena mungkin memiliki arsenik tinggi atau
                          masalah serupa.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sumber air layak tambahan diidentifikasi, tersedia, dan dapat diakses"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sumber air tambahan diidentifikasi tetapi tidak layak atau layak tetapi tidak mudah diakses"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sumber air tambahan yang tersedia "
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Memiliki air dalam jumlah yang cukup untuk semua
                      penggunaan
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[8]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Kebutuhan air akan bervariasi tergantung pada jenis
                          Fasyankes dan jumlah pasien.<br /><br />
                          Untuk menghitung kebutuhan air Fasyankes, tambahkan
                          persyaratan berikut atau standar nasional yang berlaku
                          (perhatikan bahwa persyaratan dapat bervariasi pada
                          bulan yang berbeda dalam setahun sehingga mendasarkan
                          perhitungan pada bulan yang membutuhkan air paling
                          banyak): Pasien rawat jalan (5 L/konsultasi) + rawat
                          inap (40–60 L/pasien/hari) + ruang operasi atau unit
                          bersalin (100 L/intervensi) + pusat pemberian makanan
                          kering atau tambahan (0,5–5 L/konsultasi tergantung
                          waktu tunggu) + pusat perawatan kolera (60
                          L/pasien/hari). Sumber: Standar lingkungan esensial
                          dalam perawatan kesehatan (WHO, 2008).<br /><br />

                          Jumlah ini memperhitungkan air yang diperlukan untuk
                          minum, pembersihan lingkungan dan binatu, kebersihan
                          tangan dan pengelolaan limbah. Catatan, air untuk
                          penggunaan medis tertentu (seperti dialisis) tidak
                          termasuk.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Memiliki air dalam jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Jumlah air cukup untuk 75% kebutuhan (di semua bangsal dan penggunaan)"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Jumlah air kurang dari 75% cukup"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyankes ini memiliki tangki untuk menyimpan air jika
                      terjadi gangguan pada pasokan utama, dan tangki
                      penyimpanan air dilindungi (misalnya dari peristiwa cuaca
                      ekstrem terkait iklim) dan dikelola secara memadai
                      (misalnya diperiksa, dibersihkan / didesinfeksi secara
                      teratur), dan cukup untuk memenuhi kebutuhan Fasyankes
                      selama 2 hari
                    </p>

                    <v-row>
                      <v-tooltip right v-model="showTooltip[9]" max-width="80%">
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Penyimpanan air harus dilindungi dari kontaminasi dan
                          mampu menahan peristiwa cuaca ekstrem.<br /><br />

                          Untuk menghitung kebutuhan penyimpanan air Fasyankes,
                          hitung persyaratan (indikator W_7) yang dibutuhkan
                          selama 24 jam dan kalikan dengan 2 untuk mendapatkan
                          total selama 48 jam. <br /><br />

                          Penyimpanan cadangan tambahan harus disediakan selama
                          periode berisiko tinggi. Jika memungkinkan,
                          penyimpanan lebih dari 2 hari harus disediakan dan air
                          diprioritaskan untuk layanan penting/menyelamatkan
                          jiwa (yaitu ruang bersalin bangsal perawatan
                          akut).</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air memiliki jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Cukup untuk dua hari tetapi tidak dilindungi atau dilindungi tetapi hanya cukup untuk satu hari"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Penyimpanan tersedia kurang dari satu hari yang dibutuhkan atau tidak ada yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ketersediaan Air</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana curah hujan cukup dan teratur] Sistem pemanenan
                      air hujan (dengan penyimpanan yang aman) berfungsi dan
                      menyimpan air dengan aman
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[10]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Selokan dan atap yang digunakan untuk resapan air
                          hujan dan tangki penyimpanan harus dibersihkan secara
                          teratur setidaknya setiap bulan atau sesuai kebutuhan
                          selama badai besar dan curah hujan. Sistem juga harus
                          menggunakan perangkat flush pertama yang dirancang
                          untuk mengalihkan bagian pertama dari air hujan yang
                          terkontaminasi sehingga tidak masuk ke tangki
                          penyimpanan dan kotak filter.</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Pemanenan air hujan dengan penyimpanan yang aman ada dan fungsional"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem pemanenan air hujan ada tetapi penyimpanan tidak aman atau cukup atau ada kebocoran"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pemanenan air hujan yang digunakan (meskipun air hujan tersedia)"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Konservasi Air</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Konservasi Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Strategi penghematan air digunakan untuk mengurangi
                      pemborosan air.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[11]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Strategi pengurangan air termasuk penggunaan wastafel
                          efisiensi tinggi dan aliran air rendah untuk mencuci
                          tangan, mesin cuci rendah air (dan strategi lain untuk
                          mencuci dan membersihkan), memastikan pipa dan
                          perlengkapan tidak bocor (dan menggunakan sistem untuk
                          melaporkan dan memperbaiki keran yang bocor pada hari
                          yang sama), memeriksa meter untuk menganalisis
                          penggunaan air, dan menggunakan greywater dan / atau
                          air hujan jika tersedia untuk menyiram toilet,
                          membersihkan area trotoar luar ruangan, tanaman air,
                          dll.<br /><br />

                          Dalam kasus peristiwa iklim atau keadaan darurat,
                          tindakan tambahan mungkin diperlukan, misalnya air
                          diprioritaskan untuk layanan penting/menyelamatkan
                          jiwa (yaitu ruang bersalin, bangsal perawatan akut);
                          Pengguna prioritas diidentifikasi jika terjadi
                          kekurangan (pengguna prioritas mungkin termasuk ibu
                          bersalin, anak kecil, orang tua atau kurang gizi).
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Strategi penghematan air digunakan secara efektif dan pemborosan air dihindari"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Strategi pengurangan air digunakan tetapi masih ada beberapa pemborosan air yang dapat dihindari"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada strategi pengurangan air yang digunakan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Air Minum</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Air Minum</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana desinfeksi klorin dilakukan] Air minum memiliki
                      residu klorin bebas yang sesuai (≥0,2 mg/L atau ≥0,5 mg/L
                      dalam keadaan darurat)
                    </p>
                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[12]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Air minum harus memenuhi Pedoman WHO untuk Kualitas
                          Air Minum (2017) atau standar nasional:
                          https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                          Residu klorin harus sering diukur dan dosisnya
                          disesuaikan jika residu tidak terpenuhi (perubahan pH,
                          suhu, kandungan organik dan sumber air akan
                          mempengaruhi kemanjuran klorin). Bukti residu klorin
                          yang terdokumentasi harus tersedia dari pengujian
                          sebelumnya. Jika terjadi banjir, klorin saja tidak
                          akan mendisinfeksi air secukupnya karena airnya
                          kemungkinan terlalu keruh. Untuk desinfeksi yang
                          efektif, harus ada konsentrasi sisa klorin bebas ≥ 0,5
                          mg / l setelah setidaknya 30 menit waktu kontak pada
                          pH &lt; 8,0. Residu klorin harus dipertahankan di
                          seluruh sistem distribusi. Pada titik pengiriman,
                          konsentrasi residu minimum klorin bebas harus ≥0,2 mg
                          / l. Penggunaan air yang aman (menurut pedoman
                          kualitas air minum WHO, yaitu, tidak ada E. yang dapat
                          dideteksi dalam 100 mL dan konsentrasi residu klorin
                          bebas ≥0,5 mg/l setelah setidaknya 30 menit waktu
                          kontak pada pH &lt; 8,0) meminimalkan risiko paparan
                          patogen terkait air yang berasal dari enterik dan
                          lingkungan (misalnya, Pseudomonas, Legionella) dan
                          harus tersedia untuk semua layanan klinis; minimal,
                          itu harus disediakan untuk bangsal berisiko tinggi di
                          mana beban HAI dan AMR tinggi dan di semua area ruang
                          bersalin.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air minum tersedia dengan bebas sisa klorin yang sesuai"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Residu klorin bebas ada, tetapi <0,2mg / L"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak mengetahui residu/tidak memiliki kapasitas untuk menguji residu/tidak ada air minum yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Air Minum</b>
                    <p class="ma-0 color_txt small_txt">
                      Pasokan air menimbulkan risiko rendah atau tidak beresiko
                      sama sekali terhadap kesehatan masyarakat, sebagaimana
                      diukur dengan tidak adanya Ecoli per 100 mL dan/atau yang
                      diukur dengan skor risiko inspeksi sanitasi.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[13]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Drinking-water should meet WHO Guidelines for
                          Drinking-water Quality (2017) or national standards:
                          https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                          If the E. Coli reading is anything above 10/100ml, you
                          should re-test. <br /><br />

                          WASH FIT includes Sanitary Inspection (SI) forms for 4
                          technologies: tubewell with hand pump, deep borehole
                          with motorized pump, piped distribution and storage,
                          rain water harvesting. Select one or more SI forms
                          according to the facility's water supply(ies).
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air minum bebas dari Ecoli ATAU risiko rendah menurut form inspeksi sanitasi "
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air minum memiliki E.≤10 /100ml ATAU risiko sedang sesuai dengan bentuk SI"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="E.lebih dari >10/100ml /Tidak tahu apakah E.hadir /tidak memiliki kapasitas untuk menguji /tidak ada air minum yang tersedia ATAU berisiko tinggi menurut bentuk SI"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Kualitas Air</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Kualitas Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Air perpipaan diolah dengan pengelolaan air yang aman oleh
                      penyedia layanan/otoritas kota atau air diolah secara
                      teratur di tempat
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[14]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Drinking-water should meet WHO Guidelines for
                          Drinking-water Quality (2017) or national standards:
                          https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                          If the E. Coli reading is anything above 10/100ml, you
                          should re-test. <br /><br />

                          WASH FIT includes Sanitary Inspection (SI) forms for 4
                          technologies: tubewell with hand pump, deep borehole
                          with motorized pump, piped distribution and storage,
                          rain water harvesting. Select one or more SI forms
                          according to the facility's water supply(ies).
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air diolah dan diatur melalui layanan perpipaan atau air diolah secara teratur dengan teknologi yang telah terbukti handal"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air diolah dengan teknologi yang terbukti tetapi tidak secara teratur"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air tidak diolah atau diolah dengan teknologi yang tidak memenuhi standar WHO"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Kualitas Air</b>
                    <p class="ma-0 color_txt small_txt">
                      Kualitas air dari semua pasokan air (persediaan primer,
                      cadangan dan tambahan) secara rutin diuji oleh staf / dan
                      atau otoritas independen (misalnya lembaga pengawasan)
                      sesuai dengan standar nasional
                    </p>
                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[15]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Routine water quality testing should be carried out.
                          Verification may be carried out by a member of staff
                          from the facility or an external body. Drinking water
                          should meet WHO Guidelines for Drinking-water Quality
                          (2017) or national standards:
                          https://www.who.int/publications/i/item/9789241549950
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Water quality routinely and regularly tested according to national standards"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Water quality tested but not routinely or regularly"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="No testing takes place or no national standards exist"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Air Minum</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Air Minum</b>
                    <p class="ma-0 color_txt small_txt">
                      Dispenser ( station) air minum dengan air minum yang aman
                      tersedia dan berfungsi setiap saat di ruang tunggu utama
                      dan / atau pintu masuk ke setiap bangsal dan di semua
                      kamar tempat pasien menginap atau menerima perawatan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[16]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Air minum yang aman, sebagaimana didefinisikan oleh
                          Pedoman WHO, tidak mewakili risiko signifikan terhadap
                          kesehatan selama konsumsi seumur hidup, termasuk
                          sensitivitas berbeda yang mungkin terjadi di antara
                          tahap kehidupan.<br /><br />

                          Tidak adanya kontaminasi tinja dapat ditunjukkan
                          dengan tidak adanya organisme indikator seperti E.
                          (atau coliform feses termotoleran). Jika ini tidak
                          dapat diuji, keberadaan residu klorin bebas
                          (setidaknya 0,2 mg/l) dapat digunakan sebagai indikasi
                          pengganti.<br /><br />

                          Air minum harus disimpan dengan aman dalam
                          ember/tangki bersih dengan penutup dan keran, yang
                          secara teratur dibersihkan dan didesinfeksi (kecuali
                          jika dikeluarkan dari air mancur). Air minum harus
                          dapat diakses oleh staf, pasien, dan pengasuh.<br />
                          Dalam kasus peningkatan cepat dalam pencari perawatan
                          (misalnya karena peristiwa terkait iklim), stasiun
                          pengisian staf lebih teratur, lebih banyak air
                          diperoleh, atau opsi lain (seperti perawatan in-line
                          untuk air perpipaan) digunakan. <br />
                          Air minum juga harus dapat diakses, sebagai berikut
                          <br />
                          - Jalur menuju area air minum: lebar minimal 120 cm,
                          datar dan rata, kering dan bersih dari rintangan.<br />
                          - Tanda untuk stasiun air minum memiliki kata-kata,
                          gambar dan braille dan dipajang di dinding pada jarak
                          140 cm-160 cm dari tanah.<br />
                          - Keran stasiun air minum berjarak 75 cm dari lantai
                          dan cangkir tersedia untuk pasien. Jika cangkir
                          digunakan, mereka harus dapat digunakan kembali dan
                          dicuci dengan air hangat dan sabun dan dikeringkan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air minum tersedia di semua lokasi, setiap saat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air minum tersedia tetapi hanya di beberapa tempat, hanya kadang-kadang"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Air minum tidak tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Shower</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Shower</b>
                    <p class="ma-0 color_txt small_txt">
                      [Fasyankes dengan layanan rawat inap] Setidaknya satu
                      pancuran atau area pemandian tersedia per 40 pasien rawat
                      inap atau per bangsal (mana yang lebih rendah) dan
                      berfungsi serta dapat diakses
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[17]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Air minum yang aman, sebagaimana didefinisikan oleh
                          Pedoman WHO, tidak mewakili risiko signifikan terhadap
                          kesehatan selama konsumsi seumur hidup, termasuk
                          sensitivitas berbeda yang mungkin terjadi di antara
                          tahap kehidupan.<br /><br />

                          Tidak adanya kontaminasi tinja dapat ditunjukkan
                          dengan tidak adanya organisme indikator seperti E.
                          (atau coliform feses termotoleran). Jika ini tidak
                          dapat diuji, keberadaan residu klorin bebas
                          (setidaknya 0,2 mg/l) dapat digunakan sebagai indikasi
                          pengganti.<br /><br />

                          Air minum harus disimpan dengan aman dalam
                          ember/tangki bersih dengan penutup dan keran, yang
                          secara teratur dibersihkan dan didesinfeksi (kecuali
                          jika dikeluarkan dari air mancur). Air minum harus
                          dapat diakses oleh staf, pasien, dan pengasuh.<br />
                          Dalam kasus peningkatan cepat dalam pencari perawatan
                          (misalnya karena peristiwa terkait iklim), stasiun
                          pengisian staf lebih teratur, lebih banyak air
                          diperoleh, atau opsi lain (seperti perawatan in-line
                          untuk air perpipaan) digunakan. <br />
                          Air minum juga harus dapat diakses, sebagai berikut
                          <br />
                          - Jalur menuju area air minum: lebar minimal 120 cm,
                          datar dan rata, kering dan bersih dari rintangan.<br />
                          - Tanda untuk stasiun air minum memiliki kata-kata,
                          gambar dan braille dan dipajang di dinding pada jarak
                          140 cm-160 cm dari tanah.<br />
                          - Keran stasiun air minum berjarak 75 cm dari lantai
                          dan cangkir tersedia untuk pasien. Jika cangkir
                          digunakan, mereka harus dapat digunakan kembali dan
                          dicuci dengan air hangat dan sabun dan dikeringkan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kamar mandi tersedia per bangsal atau per 40 pasien dan fungsional serta dapat diakses"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kamar mandi tersedia, tetapi kurang dari 1 pancuran yang berfungsi dan dapat diakses per 40 pasien / per bangsal"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pancuran yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Shower</b>
                    <p class="ma-0 color_txt small_txt">
                      Kamar mandi berfungsi untuk wanita yang privasi dan dapat
                      dikunci tersedia di area persalinan dan persalinan
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ya, pancuran fungsional atau ruang untuk mencuci tersedia di area persalinan dan persalinan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Shower/tempat cuci fungsional tersedia tetapi tidak di area persalinan dan pengiriman, atau di area yang benar tetapi tidak berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada shower / tempat cuci yang tersedia untuk wanita"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>
            <!-- ========================================STEP 2 ============================================= -->
            <v-stepper-content step="2" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Toilet</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyakes memiliki jumlah toilet yang cukup untuk pasien
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[18]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          ><strong>Fasilitas sanitasi yang ditingkatkan</strong
                          >: termasuk toilet siram ke saluran pembuangan atau
                          septic tank yang dikelola dan lubang perendaman,
                          jamban VIP, jamban lubang dengan toilet lempengan dan
                          pengomposan.<br /><br />

                          <strong>Nomor</strong>: Lebih banyak jamban mungkin
                          diperlukan tergantung pada ukuran fasilitas. Untuk
                          fasilitas yang lebih besar dengan beberapa bangsal, di
                          mana dua toilet rawat jalan tidak mencukupi,
                          disarankan (jika memungkinkan) bahwa setiap departemen
                          rawat jalan memiliki dua toilet. Toilet juga dapat
                          digunakan oleh penjaga, pengasuh, dan pengunjung.
                          Jumlah pengunjung yang besar menambah tuntutan pada
                          infrastruktur sanitasi dan pembersihan dan ini harus
                          dipertimbangkan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Dua atau lebih toilet untuk pasien rawat jalan ditambah satu per 20 pengguna/rawat inap."
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Jumlah toilet dipenuhi untuk pasien rawat jalan atau rawat inap, tetapi tidak keduanya."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Baik rawat inap maupun rawat jalan tidak memiliki jumlah toilet yang cukup atau toilet yang ada tidak diperbaiki"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua toilet pasien tersedia dan dapat digunakan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[19]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span>
                          <strong>Tersedia dan dapat digunakan</strong>: toilet
                          / jamban harus memiliki pintu yang tidak terkunci saat
                          tidak digunakan (atau yang kuncinya tersedia kapan
                          saja) dan dapat dikunci dari dalam selama penggunaan,
                          tidak boleh ada lubang besar dalam struktur, lubang
                          atau lubang tidak boleh diblokir, air harus tersedia
                          untuk toilet flush / pour flush dan tidak boleh ada
                          retakan, atau kebocoran pada struktur toilet. Itu
                          harus berada di dalam halaman fasilitas dan harus
                          bersih seperti yang dicatat dengan tidak adanya
                          limbah, kotoran dan kotoran yang terlihat dan
                          serangga.<br /><br />

                          <strong
                            >Kriteria tambahan yang dapat digunakan</strong
                          >
                          untuk tingkat layanan yang lebih tinggi: <br />
                          Toilet harus <br />
                          • Terletak di area fasilitas yang kurang rawan banjir,
                          erosi, dll.<br />
                          • Secara teratur diperiksa untuk kerusakan<br />
                          • Dibersihkan lebih teratur ketika pengguna meningkat
                          <br />
                          • Diangkat dan/atau merupakan toilet tertutup
                          sementara yang dapat dikosongkan secara teratur
                          <br />
                          • Lubang dikosongkan secara teratur<br />
                          • Kotoran diolah dengan aman di tempat (misalnya
                          melalui tangki septik/bidang pelindian yang berfungsi)
                          atau dibuang ke sistem saluran pembuangan yang
                          berfungsi, atau diangkut dengan aman ke luar lokasi ke
                          area perawatan terpusat.<br />
                          • Toilet dengan lubang terbuka atau soakaway harus
                          terletak setidaknya 30m dari sumber air dan minimal
                          1,5m di atas meja air. Lihat catatan di bawah S_8
                          Penahanan. <br />
                          • Di daerah yang rawan air atau banjir, toilet
                          efisiensi tinggi (desain toilet rendah atau tanpa air
                          misalnya toilet kering konversi urin) harus
                          digunakan.<br />
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua toilet pasien tersedia dan dapat digunakan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua toilet pasien tersedia dan dapat digunakan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua toilet pasien tersedia dan dapat digunakan "
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua toilet memiliki tempat cuci tangan yang berfungsi
                      dalam jarak 5 meter
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[20]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          ><strong
                            >Functioning hand hygiene station at toilets</strong
                          >: must include soap and water. Alcohol-based hand rub
                          is not sufficient to remove faecal matter from
                          hands.<br />
                          This indicator is used to calculate basic hand hygiene
                          but is included in the sanitation section for ease of
                          data collection flow
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua toilet memiliki tempat cuci tangan fungsional dalam jarak 5 meter"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="At least 50% of toilets have functioning hand washing stations within 5 metres "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Fewer than 50% of toilets have functioning hand washing stations within 5 metres"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Setidaknya satu toilet yang layak tersedia untuk staf, dan
                      toilet dipisahkan atau diberi label dengan jelas
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[21]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Toilet terpisah yang ditingkatkan untuk staf
                          diperlukan untuk memenuhi persyaratan sanitasi dasar,
                          sebagaimana diukur oleh JMP.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Setidaknya ada satu toilet berfungsi untuk digunakan staf dan dipisahkan / diberi label dengan jelas "
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toilet ada untuk digunakan staf, tetapi toilet tidak dipisahkan / diberi label dengan jelas atau tidak berfungsi "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="No separate toilet exists for staff use, or toilets are unimproved"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Toilet yang layak dipisahkan / diberi label dengan jelas
                      untuk pria, wanita yang memberikan privasi
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Toilet terpisah untuk pengguna pria / wanita ada dan diberi label dengan jelas (dan memberikan privasi bagi pengguna)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toilet terpisah ada tetapi tidak diberi label dengan jelas"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada toilet terpisah dan tidak ada privasi di toilet lain atau toilet yang tidak disetujui"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Setidaknya satu toilet yang dapat digunakan untuk memenuhi
                      kebutuhan manajemen kebersihan menstruasi
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[23]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Toilet harus memiliki tempat sampah untuk pembuangan
                          limbah atau area untuk mencuci, dengan air yang
                          tersedia. Idealnya, pembalut wanita harus tersedia di
                          fasilitas atau di dekatnya untuk wanita menstruasi dan
                          pasca-kelahiran untuk dibeli.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Satu atau lebih toilet yang dapat digunakan melayani MKM"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Ada ruang bagi wanita untuk mencuci tetapi tidak ada air yang tersedia, toilet tidak bersih / rusak atau tempat sampah untuk pembuangan limbah tersedia tetapi penuh"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada fasilitas MKM yang tersedia atau fasilitas yang tersedia tetapi toilet tidak dapat digunakan atau toilet tidak dapat digunakan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Toilet</b>
                    <p class="ma-0 color_txt small_txt">
                      Setidaknya satu toilet fungsional yang ditingkatkan
                      memenuhi kebutuhan orang-orang dengan mobilitas terbatas
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[24]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Toilet dapat dipertimbangkan untuk memenuhi kebutuhan
                          orang-orang dengan mobilitas yang berkurang jika
                          memenuhi kondisi berikut: dapat diakses tanpa tangga
                          atau tangga, pegangan tangan untuk dukungan terpasang
                          baik ke lantai atau dinding samping, pintunya
                          setidaknya selebar 80 cm, toilet memiliki kursi yang
                          ditinggikan (antara 40-48 cm dari lantai), sandaran
                          dan bilik memiliki ruang untuk sirkulasi/ manuver (150
                          x 150 cm). Wastafel, keran, dan air di luar juga harus
                          dapat diakses dan bagian atas wastafel 75 cm dari
                          lantai (dengan jarak selutut). Sakelar untuk lampu,
                          jika relevan, juga harus berada pada ketinggian yang
                          dapat diakses (maks 120 cm). Semua spesifikasi
                          didasarkan pada ISO 21542: 2011 (Konstruksi bangunan -
                          Aksesibilitas dan kegunaan lingkungan binaan) tersedia
                          di:
                          http://www.iso.org/iso/home/store/catalogue_tc/catalogue_detail.htm?csnumber=50498
                          Tempat cuci tangan di toilet ini juga harus dapat
                          diakses, sesuai dengan kriteria berikut: keran harus
                          memiliki pegangan tipe tuas, wastafel memiliki rel
                          pegangan di kedua sisi, dan sabun (atau gosok tangan
                          berbasis alkohol) dan tisu mudah dijangkau. Ketinggian
                          wastafel adalah 75 cm untuk pembersihan lutut.</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Satu atau lebih toilet fungsional memenuhi kebutuhan orang-orang dengan mobilitas terbatas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toilet memenuhi kebutuhan orang dengan mobilitas yang berkurang tetapi tidak fungsional atau toilet berfungsi tetapi hanya sebagian memenuhi kebutuhan orang dengan mobilitas yang berkurang"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada toilet untuk pengguna difabel atau toilet yang tidak disetujui"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== contain 1 -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Faecal sludge management</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                    <b>Faecal sludge management</b>
                    <p class="ma-0 color_txt small_txt">
                      Lumpur tinja sepenuhnya terkandung untuk kemudian
                      pengosongan dan perawatan di luar lokasi atau sepenuhnya
                      terkandung dan dirawat di tempat.
                    </p>
                    <p class="ma-0 color_txt small_txt">
                      Limbah cair disimpan sepenuhnya atau mengalir ke tanah
                      dari dasar wadah, atau melalui bidang leach, lubang rendam
                      atau saluran air tertutup, atau disimpan dengan aman.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[25]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Air limbah harus dikelola dengan aman melalui
                          penggunaan pengolahan di tempat (yaitu septic tank
                          diikuti oleh lubang drainase) atau dikirim ke sistem
                          saluran pembuangan yang berfungsi. Lihat Pedoman WHO
                          tentang Sanitasi dan Kesehatan Bab 3.3 dan formulir
                          Inspeksi Sanitasi WHO untuk sistem sanitasi di tempat.
                          Penahanan yang aman memastikan produk yang dihasilkan
                          dari toilet disimpan dalam teknologi penahanan
                          dan/atau dibuang ke lingkungan setempat dengan cara
                          yang tidak membuat siapa pun terpapar bahaya atau
                          mencemari sumber air. <br /><br />

                          Penahanan mengacu pada wadah, biasanya terletak di
                          bawah permukaan tanah, di mana toilet terhubung /
                          berada. Ini termasuk kontainer yang dirancang
                          untuk:<br />
                          • penahanan, penyimpanan, dan pengolahan lumpur tinja
                          dan limbah (misalnya septic tank, jamban kering dan
                          basah, toilet pengomposan, kubah dehidrasi, tangki
                          penyimpanan urin, dll.); atau
                          <br />
                          • penahanan dan penyimpanan (tanpa pengolahan) lumpur
                          tinja dan air limbah (misalnya tangki berlapis penuh,
                          sanitasi berbasis wadah).<br /><br />

                          Di mana lindi dari teknologi permeabel atau limbah
                          dari teknologi kedap air larut ke dalam struktur bawah
                          tanah, ada risiko bahwa air tanah dan air permukaan di
                          dekatnya dapat tercemar, berpotensi mencemari sumber
                          air lokal yang digunakan untuk minum dan tugas-tugas
                          rumah tangga. Sebagai aturan umum dan tanpa penilaian
                          risiko yang lebih rinci untuk air tanah, <br />
                          • bagian bawah wadah permeabel dan lubang rendam atau
                          ladang pelindian harus tidak kurang dari 1,5 m hingga
                          2,0 m di atas permukaan air pada tingkat tertinggi
                          sepanjang tahun, • wadah permeabel dan bidang
                          pelindian harus terletak di bawah gradien, dan <br />
                          • Setidaknya 10 m jarak horizontal dari sumber air
                          minum.</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Inspeksi visual wadah dan drainase menunjukkan integritas struktural, tidak ada kebocoran atau kerusakan, tidak ada kolam yang terlihat atau bau yang kuat yang menunjukkan kebocoran ke area lokal. Operator melaporkan tidak ada kebocoran dalam kondisi cuaca basah dan kering."
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak dapat menentukan penahanan dari inspeksi visual dan/atau operator melaporkan kebocoran musiman. "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Inspeksi dan respons operator menunjukkan kerusakan pada wadah, ponding, pembuangan limbah cair ke saluran terbuka atau tanah terbuka."
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Wastewater management</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Wastewater management</b>
                    <p class="ma-0 color_txt small_txt">
                      Toilets are connected without leaks to a public sewer
                      system. The sewer conveys excreta and wastewater with no
                      leaks/overflows to treatment. [Sewered systems]
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[26]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Alat angkut yang aman membatasi paparan pekerja yang
                          melakukan operasi dan pemeliharaan, masyarakat yang
                          tinggal dan bekerja di sekitar pekerjaan, dan
                          masyarakat luas yang masing-masing dapat terpapar
                          patogen melalui konsumsi dan inhalasi patogen feses.
                          Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                          3.4<br /><br />

                          Sistem berbasis saluran pembuangan terdiri dari
                          jaringan pipa bawah tanah. Jenis saluran pembuangan
                          meliputi:<br />
                          • Selokan gravitasi konvensional: Bawa air hitam dari
                          toilet dan greywater bersama dengan, di banyak kasus,
                          limbah industri dan air badai melalui pipa berdiameter
                          besar ke fasilitas pengolahan, menggunakan gravitasi
                          (dan pompa bila perlu)<br />
                          • Selokan yang disederhanakan: desain berbiaya lebih
                          rendah yang dipasang menggunakan pipa yang lebih kecil
                          pada kedalaman yang lebih rendah dan lebih dangkal
                          gradien dari selokan gravitasi konvensional.<br />
                          • Selokan bebas padatan: desain serupa dengan saluran
                          pembuangan yang disederhanakan tetapi termasuk
                          pra-perawatan lumpur untuk hapus padatan.</span
                        >
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Denah bangunan dan laporan operator mengonfirmasi toilet fasilitas terhubung ke selokan. Tidak ada laporan luapan di lahan fasilitas atau di masyarakat setempat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak dapat menentukan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Laporan seringnya terjadi kebocoran dengan alasan fasilitas dari operator utilitas saluran pembuangan sering mengalami kebocoran / luapan di masyarakat"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Wastewater management</b>
                    <p class="ma-0 color_txt small_txt">
                      Lumpur tinja dari penampungan secara berkala dikosongkan
                      tanpa tumpahan oleh personel terlatih dengan peralatan
                      pelindung yang sesuai dan a) dikeluarkan di luar lokasi
                      untuk perawatan atau b) dibuang dengan aman dengan
                      mengubur di tempat [Tidak berlaku untuk lubang yang
                      tertutup dan ditutup saat penuh. Pergi ke S_10a]
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[27]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Alat angkut yang aman membatasi paparan pekerja yang
                          melakukan operasi dan pemeliharaan, masyarakat yang
                          tinggal dan bekerja di sekitar pekerjaan, dan
                          masyarakat luas yang masing-masing dapat terpapar
                          patogen melalui konsumsi dan inhalasi patogen feses.
                          Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                          3.4. Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                          Bab 3.4. <br /><br />

                          Pengosongan dan pengangkutan manual dan bermotor
                          mengacu pada berbagai cara di mana lumpur tinja dapat
                          dikeluarkan dari lokasi fasilitas. <br />
                          Pengosongan lubang, brankas, dan tangki secara manual
                          dapat dilakukan dengan salah satu dari dua cara; baik
                          menggunakan ember dan sekop; atau menggunakan pompa
                          lumpur portabel yang dioperasikan secara manual
                          (meskipun ini mungkin mekanis, masih memerlukan
                          penanganan manual/fisik). <br />
                          Pengosongan manual dan bermotor dapat membawa risiko
                          kemungkinan kontak dengan bahan feses dan dalam
                          beberapa kasus pengosongan bermotor perlu
                          dikombinasikan dengan pengosongan manual untuk
                          menghilangkan bahan terpadat. Beberapa teknologi
                          penahanan hanya dapat dikosongkan secara manual.
                          Teknologi ini paling sering dikosongkan dengan sekop
                          karena bahannya padat dan tidak dapat dilepas dengan
                          ruang hampa udara atau pompa. Lumpur tinja yang
                          dikosongkan dikumpulkan dalam tong atau tas atau
                          dimasukkan ke dalam gerobak dan diangkut menjauh dari
                          lokasi.<br /><br />

                          Pengosongan dan pengangkutan bermotor (juga dikenal
                          sebagai pengosongan dan pengangkutan mekanis) mengacu
                          pada penggunaan kendaraan atau perangkat apa pun yang
                          dilengkapi dengan pompa bermotor dan tangki
                          penyimpanan untuk mengosongkan dan mengangkut lumpur
                          tinja. Orang-orang diharuskan untuk mengoperasikan
                          pompa dan manuver selang, tetapi lumpur tinja tidak
                          diangkat atau diangkut secara manual. Sistem basah
                          seperti septic tank dan fully lined tank biasanya
                          dikosongkan menggunakan pengosongan dan pengangkutan
                          bermotor.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kontainer telah dikosongkan dalam 5 tahun terakhir (atau sesuai dengan frekuensi pengosongan yang dijadwalkan) oleh personel terlatih dengan peralatan pelindung yang sesuai dan a) dipindahkan ke luar lokasi untuk perawatan b) lumpur tinja dibuang dengan aman dengan mengubur di tempat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak dapat menentukan frekuensi pengosongan atau keamanan pembuangan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak pernah dikosongkan atau diketahui pembuangan yang tidak aman tanpa perawatan di lingkungan setempat (misalnya di sungai atau di pertanian)"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Wastewater management</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Wastewater management</b>
                    <p class="ma-0 color_txt small_txt">
                      Instalasi pengolahan air limbah yang dirancang dengan baik
                      dan dikelola dengan baik, dengan catatan operasi yang
                      dapat diakses publik, menyediakan setidaknya pengolahan
                      sekunder dan memenuhi standar kinerja
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[28]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Desain dan pengoperasian fasilitas perawatan
                          dilakukan sesuai dengan kriteria desainnya dan sesuai
                          dengan tujuan penggunaan/pembuangan akhir lokal.
                          <strong
                            >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                            Bab 3.5</strong
                          >
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="IPAL yang dirancang dengan baik dengan catatan yang tersedia untuk umum yang menunjukkan bahwa IPAL tersebut memenuhi standar kinerja perawatan lokal/nasional"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="IPAL yang berfungsi ada. Kinerja tidak jelas atau tidak sesuai standar"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="IPAL tidak berfungsi atau tidak ada"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Faecal sludge management</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Faecal sludge management</b>
                    <p class="ma-0 color_txt small_txt">
                      Pabrik pengolahan lumpur tinja yang dirancang dengan baik
                      dan dikelola dengan baik, dengan catatan operasi yang
                      tersedia untuk umum, digunakan dan memenuhi standar
                      kinerja
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[29]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Desain dan pengoperasian fasilitas perawatan
                          dilakukan sesuai dengan kriteria desainnya dan sesuai
                          dengan tujuan penggunaan/pembuangan akhir lokal.
                          <strong
                            >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                            Bab 3.5</strong
                          >
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Well designed and managed FSTP with publicly available record showing it meets local/national  treatment performance standards"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Functioning FSTP exists. Performance unclear"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="FSTP is non-functioning or non-existent"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Stormwater management</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Stormwater management</b>
                    <p class="ma-0 color_txt small_txt">
                      Sistem drainase air hujan (yaitu air hujan) dan greywater
                      tersedia yang mengalihkan air dari fasilitas ke drainase
                      yang aman atau area ladang larut
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[30]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Mengurangi genangan air juga penting untuk
                          pengendalian vektor. Air harus mengalir jauh dari area
                          publik. Tidak ada kebocoran dari pipa atau lubang
                          perendaman, perendaman lebih dari 30 m dari sumber
                          air, dengan perangkap minyak dan tidak ada genangan
                          air yang terlihat. Wastafel utilitas atau saluran
                          pembuangan (yaitu bukan wastafel yang digunakan untuk
                          kebersihan tangan) harus tersedia di dalam fasilitas
                          di area layanan pembersihan lingkungan dan area pintu
                          air yang ditunjuk. Saluran air harus mengarah ke
                          sistem air limbah di tempat (misalnya sistem
                          perendaman) atau ke sistem saluran pembuangan yang
                          berfungsi
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sistem drainase ada, fungsional (tidak tersumbat) dan berhasil mengalihkan air dari fasilitas ke area penyaringan alami yang aman (misalnya tidak langsung ke rumah tangga atau area komunitas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem drainase di tempat tetapi tidak cukup untuk volume air limbah atau tersumbat"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sistem drainase di tempat"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Stormwater management</b>
                    <p class="ma-0 color_txt small_txt">
                      Greywater dan/atau stormwater ditangkap dan digunakan
                      kembali untuk mencuci, membersihkan, menyiram tanaman, dan
                      menyiram toilet
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[31]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Penggunaan kembali greywater dan/atau air badai
                          mungkin berguna sebagai bagian dari konservasi air.
                          Air tersebut tidak perlu diolah untuk digunakan
                          kembali selama tidak dicampur dengan air hitam/limbah
                          dan tidak ada potensi risiko infeksi atau infiltrasi
                          lainnya.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sistem untuk penangkapan dan penggunaan kembali air limbah domestik dan air badai sudah ada dan beroperasi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem untuk menangkap air limbah domestikr / air badai tersedia tetapi tidak digunakan secara maksimal"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sistem untuk menangkap Air limbah domestik atau air badai"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Greywater management</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Greywater management</b>
                    <p class="ma-0 color_txt small_txt">
                      [Hanya jika ada sistem greywater] Greywater dari wastafel
                      dan fasilitas binatu ditangkap dengan aman dan diarahkan
                      ke saluran pembuangan, ladang pelarut, lubang rendam atau
                      saluran air tertutup tanpa koneksi silang dengan pasokan
                      air minum.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[32]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Greywater juga dapat digunakan kembali untuk menyiram
                          tanaman dan pembilasan toilet.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Air limbah domestik ditangkap dengan aman dan memiliki pipa ledeng terpisah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem Greywater menangkap air tetapi beberapa risiko kontaminasi melalui koneksi silang"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem air limbah domestik tidak berfungsi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>

            <!-- ========================================STEP 3 ============================================= -->
            <v-stepper-content step="3" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Segregation</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Segregation</b>
                    <p class="ma-0 color_txt small_txt">
                      Wadah pengumpulan limbah fungsional tersedia di dekat
                      semua titik timbulan limbah untuk limbah non-infeksius
                      (umum), limbah infeksius, dan limbah tajam
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[33]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Fungsional berarti harus ada setidaknya tiga wadah
                          ("sistem 3-bin") yang tidak lebih dari tiga perempat
                          penuh, yang anti bocor dengan penutup dan semuanya
                          diberi label dengan jelas (yaitu mudah dibedakan
                          menurut warna, label atau simbol). Bin liner / kantong
                          untuk limbah infeksius direkomendasikan jika sumber
                          daya memungkinkan. Tempat sampah tambahan yang tidak
                          menular mungkin diperlukan untuk dengan mudah
                          memisahkan limbah untuk didaur ulang. <br /><br />

                          Titik timbulan sampah adalah di mana saja perawatan
                          diberikan di mana limbah dihasilkan dari memberikan
                          perawatan atau kegiatan medis lainnya. <br /><br />

                          Jika memungkinkan, benda tajam/kotak pengaman kardus
                          digunakan sebagai pengganti kotak plastik, untuk
                          mengurangi emisi berbahaya saat dibakar (terutama di
                          mana pembakaran suhu rendah digunakan).
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Wadah pengumpulan limbah fungsional untuk memisahkan limbah ada di semua titik timbulan limbah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tempat sampah fungsional di beberapa tetapi tidak semua titik timbulan limbah"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada tempat sampah atau pembuangan benda tajam terpisah"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Segregation</b>
                    <p class="ma-0 color_txt small_txt">
                      Sampah dipilah dengan benar di semua titik timbulan sampah
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[34]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Setidaknya tiga tempat sampah berlabel jelas atau
                          berkode warna harus ada untuk memisahkan (1) limbah
                          benda tajam18, (2) limbah infeksius19, dan (3) limbah
                          umum yang tidak menular. Tempat sampah tidak boleh
                          lebih dari tiga perempat (75%) penuh, dan setiap
                          tempat sampah tidak boleh mengandung limbah selain
                          yang sesuai dengan labelnya. Tempat sampah harus
                          sesuai dengan jenis sampah yang akan dikandungnya;
                          Wadah Sharps harus tahan tusukan dan yang lainnya
                          harus anti bocor. Tempat sampah untuk limbah benda
                          tajam dan limbah infeksius harus memiliki tutup. Area
                          konsultasi adalah ruangan atau area di dalam fasilitas
                          perawatan kesehatan tempat perawatan atau perawatan
                          diberikan. Verifikasi bahwa semua tempat sampah di
                          fasilitas memiliki limbah yang benar di dalamnya.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Waste is correctly segregated Sampah dipisahkan dengan benar di semua titik timbulan sampah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Lebih dari 75% tempat sampah memiliki limbah yang benar"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kurang dari 75% tempat sampah digunakan untuk limbah yang benar"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Segregation</b>
                    <p class="ma-0 color_txt small_txt">
                      Pengingat untuk pemilahan sampah yang benar terlihat jelas
                      di semua titik timbulan sampah
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Pengingat terlihat jelas di semua titik timbulan sampah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Pengingat tersedia di beberapa tetapi tidak semua titik timbulan limbah"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pengingat yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
                <!-- ============================================================================================================== contain 1 -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Personnel</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Peralatan dan sumber daya pelindung yang sesuai untuk
                      melakukan kebersihan tangan tersedia untuk semua staf yang
                      bertanggung jawab untuk menangani limbah, dan bertanggung
                      jawab atas pengolahan dan pembuangan limbah
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[35]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Protective equipment for people handling waste
                          management includes: face mask, thick gloves, long
                          sleeved shirt, apron, glasses/goggles and tough rubber
                          boots. <br />
                          Products (water and soap or alcohol hand rub) for hand
                          hygiene should also be available.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sumber daya untuk kebersihan tangan dan peralatan pelindung tersedia"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa peralatan tersedia, tetapi tidak untuk semua staf, atau tersedia tetapi rusak"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada peralatan yang tersedia untuk staf"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Waste reduction</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Waste reduction</b>
                    <p class="ma-0 color_txt small_txt">
                      Pengingat dan pelatihan tersedia untuk mempromosikan dan
                      memantau penggunaan alat pelindung diri (APD) secara
                      rasional (misalnya sarung tangan yang hanya digunakan saat
                      ditunjukkan)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[36]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Penggunaan dan penyalahgunaan APD yang berlebihan
                          dapat berkontribusi pada penyebaran organisme yang
                          berpotensi patogen, terutama dengan tidak adanya
                          kebersihan tangan. Mencegah dan mengurangi jumlah
                          limbah yang dihasilkan, melalui penggunaan APD yang
                          aman dan rasional, adalah salah satu cara paling
                          efektif untuk mengelola dan mengurangi dampak manusia
                          dan lingkungan. Mengirim sampah ke TPA harus menjadi
                          pilihan terakhir. Lihat piramida sarung tangan:
                          https://www.who.int/gpsc/5may/Glove_Use_Information_Leaflet.pdf
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Pengingat dan pelatihan di tempat dan APD digunakan secara rasional"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa pengingat atau pelatihan di tempat tetapi lebih banyak yang dapat dilakukan untuk mengurangi penggunaan APD"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pengingat atau pelatihan di tempat"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Waste reduction</b>
                    <p class="ma-0 color_txt small_txt">
                      Strategi untuk mengurangi jumlah limbah yang dihasilkan
                      digunakan di seluruh fasilitas, termasuk pengadaan barang
                      menggunakan lebih sedikit kemasan dan kemasan yang lebih
                      berkelanjutan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[37]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Contoh pengurangan limbah (mengatasi limbah "di
                          sumber") termasuk manajemen penyimpanan (kedaluwarsa
                          pertama, pertama keluar), pengadaan produk dengan
                          kemasan lebih sedikit atau kemasan yang dapat didaur
                          ulang, atau mendorong penggunaan obat tablet sebagai
                          alternatif injeksi untuk mengurangi limbah benda
                          tajam. Langkah lain adalah penggunaan APD yang aman
                          dan rasional, termasuk celemek, masker, dan coverall.
                          Semua APD yang digunakan kembali harus dicuci dan
                          didesinfeksi dengan benar sesuai dengan standar
                          internasional untuk mencegah penularan infeksi.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Strategi ada dan diterapkan secara konsisten di seluruh fasilitas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Strategi ada tetapi tidak diterapkan secara konsisten atau efektif"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada strategi yang ada"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Waste reduction</b>
                    <p class="ma-0 color_txt small_txt">
                      [Tidak berlaku jika tidak ada daur ulang lokal yang
                      tersedia] Limbah tidak berbahaya yang dapat didaur ulang
                      dipisahkan dan dikirim ke pabrik daur ulang kota
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[38]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Barang-barang umum yang dihasilkan di fasilitas
                          perawatan kesehatan yang dapat didaur ulang termasuk
                          kertas, kardus, dan plastik. Bahan yang tidak dapat
                          didaur ulang adalah makanan, logam, kaca, tekstil, dan
                          kayu yang dibuang.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ada sistem di mana barang yang dapat didaur ulang disortir dan dikirim ke pabrik daur ulang"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa daur ulang terjadi tetapi sistem dapat ditingkatkan (misalnya pemisahan yang lebih baik, jumlah daur ulang yang lebih besar)"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada daur ulang yang terjadi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Storage</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Storage</b>
                    <p class="ma-0 color_txt small_txt">
                      Tersedia area penyimpanan limbah khusus yang dipagari dan
                      aman, dan dengan kapasitas yang cukup, di mana limbah
                      tajam, infeksius, dan tidak menular disimpan secara
                      terpisah
                    </p>
                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[39]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span>
                          Dalam kasus banjir, limbah harus disimpan dalam wadah
                          yang ditinggikan dan / atau diangkut ke luar
                          lokasi.<br />
                          Penyimpanan tambahan juga tersedia ketika limbah
                          tambahan dihasilkan selama peristiwa iklim dan /atau
                          keadaan darurat.<br /><br />

                          Area berpagar harus dilindungi dari banjir, berjajar
                          dan tertutup. Tidak ada limbah perawatan kesehatan
                          yang tidak terlindungi yang terlihat.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Tersedia area penyimpanan limbah khusus dan berpagar, dengan kapasitas yang cukup dan limbah yang disimpan secara terpisah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Area penyimpanan limbah khusus tersedia tetapi tidak dipagari atau aman atau tidak cukup kapasitas atau semua limbah dikelompokkan bersama"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada area limbah khusus yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Storage</b>
                    <p class="ma-0 color_txt small_txt">
                      Limbah infeksius disimpan tidak lebih lama dari batas aman
                      (sebagaimana ditentukan oleh iklim) sebelum
                      pengolahan/pembuangan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[40]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span>
                          Unless a refrigerated storage room is available,
                          storage times (e.g. the time between generation and
                          treatment) should not exceed the following periods for
                          infectious waste:<br />
                          • Temperate climate: 72 hours in winter / 48 hours in
                          summer.<br />
                          • Warm climate: 48 hours during the cool season / 24
                          hours during the hot season.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Limbah infeksius disimpan tidak lebih lama dari batas waktu aman"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Limbah infeksius disimpan di luar batas waktu yang aman, tetapi kurang dari sebulan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Limbah tidak disimpan dengan aman atau disimpan tetapi lebih dari sebulan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Treatment</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Treatment</b>
                    <p class="ma-0 color_txt small_txt">
                      Teknologi pengolahan limbah (incinerator atau teknologi
                      pengolahan alternatif) untuk pengolahan limbah infeksius
                      dan tajam dibangun dengan standar yang sesuai, terpelihara
                      dengan baik, fungsional dan memiliki kapasitas yang cukup
                      untuk limbah yang dihasilkan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[41]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span>
                          Untuk teknologi pengobatan alternatif (misalnya
                          autoklaf) pasokan air yang andal harus dijamin. Agar
                          dianggap fungsional, idealnya tidak ada kerusakan
                          besar dalam 6 bulan terakhir, dan masalah apa pun
                          seharusnya diperbaiki dalam waktu satu minggu.
                          <br />
                          Insinerator fungsional harus mencapai suhu minimum
                          yang diperlukan untuk pembakaran lengkap, emisi
                          dievakuasi dengan benar, segel pintu, tidak ada
                          akumulasi residu limbah di dalamnya, dll.
                          <br /><br />

                          Upaya dilakukan untuk meningkatkan efisiensi dan
                          keamanan insinerator, termasuk mencapai suhu yang
                          lebih tinggi, dengan tujuan memenuhi konvensi
                          Stockholm (lihat
                          http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx
                          untuk informasi lebih lanjut). <br />
                          Insinerator (jika dirancang untuk limbah infeksius dan
                          bukan hanya limbah umum) harus mengikuti persyaratan
                          desain khusus (misalnya penggunaan batu bata api/batu
                          bata tahan api dan mortar (vs. batu bata bangunan
                          umum) yang dapat menahan suhu yang diperlukan untuk
                          insinerator ini (lebih dari 800 °C). Untuk pembakaran
                          total, diperlukan insinerator bilik ganda yang
                          masing-masing mencapai suhu di atas 800 °C dan 1100
                          °C. Jika insinerator ganda tidak tersedia dan ada
                          kebutuhan mendesak untuk perlindungan kesehatan
                          masyarakat, insinerator skala kecil dapat digunakan.
                          Ini melibatkan kompromi antara dampak lingkungan dari
                          pembakaran terkendali dengan kebutuhan utama untuk
                          melindungi kesehatan masyarakat jika satu-satunya
                          alternatif adalah pembuangan sembarangan. Keadaan ini
                          ada dalam banyak situasi yang berkembang dan
                          pembakaran skala kecil dapat menjadi respons realistis
                          terhadap kebutuhan mendesak. <br />
                          Limbah dapat diolah di luar lokasi. Jika demikian,
                          harus ada cara untuk mengonfirmasi bahwa itu
                          diperlakukan dengan aman setelah dikeluarkan dari
                          tempat fasilitas. <br />
                          WHO (2014) Pengelolaan limbah yang aman dari kegiatan
                          pelayanan kesehatan <br />
                          WHO (2017) Pengelolaan limbah yang aman dari kegiatan
                          perawatan kesehatan: Ringkasan <br />
                          WHO (2019) Tinjauan teknologi untuk pengobatan infeksi
                          dan limbah tajam dari fasilitas perawatan kesehatan
                          <br />
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Teknologi dibangun dengan standar yang sesuai, terpelihara dengan baik, fungsional dan berkapasitas memadai"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Teknologi tidak dibangun untuk standar yang benar atau tidak memiliki kapasitas yang memadai"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Teknologi tidak berfungsi dan tidak memiliki kapasitas yang memadai"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Treatment</b>
                    <p class="ma-0 color_txt small_txt">
                      Limbah dikumpulkan untuk pengolahan di luar lokasi dengan
                      aman dan teratur dan dikirim ke fasilitas pengolahan
                      limbah berlisensi yang sesuai
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[42]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Limbah harus diolah dan dibuang dengan aman setelah
                          meninggalkan fasilitasi. Ini harus dilakukan melalui
                          layanan berlisensi atau terakreditasi.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sampah dikumpulkan secara teratur dan aman serta dikirim ke fasilitas pengolahan yang sesuai"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Limbah dikumpulkan secara teratur dan aman tetapi fasilitas pengolahan belum diverifikasi "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Limbah tidak dikumpulkan dengan aman atau teratur atau dikirim ke fasilitas yang sesuai atau berlisensi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Disposal</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Disposal</b>
                    <p class="ma-0 color_txt small_txt">
                      Lubang pemakaman fungsional, tempat pembuangan limbah
                      berpagar, atau penjemputan kota tersedia untuk pembuangan
                      limbah tidak menular (tidak berbahaya/umum)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[43]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Lubang limbah harus ditutup untuk menghindari banjir
                          dan tidak terlalu penuh.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Lubang fungsional, area berpagar atau penjemputan kota tersedia dan cukup untuk memenuhi permintaan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Lubang di fasilitas tetapi dimensinya tidak mencukupi; terlalu penuh atau tidak dipagari dan dikunci; pengambilan sampah kota yang tidak teratur, dll."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada lubang atau metode pembuangan lain yang digunakan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Disposal</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana ada risiko banjir] Lubang limbah dibangun untuk
                      menahan peristiwa dan keadaan darurat terkait iklim
                      (misalnya banjir) dan / atau tempat penyimpanan limbah
                      cadangan tersedia
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[44]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Lubang limbah harus dibangun di atas tanah yang
                          ditinggikan untuk mencegah luapan selama banjir. Dasar
                          lubang harus setidaknya 1,5 meter di atas permukaan
                          air.<br />
                          Jika banjir mungkin terjadi, alternatif tersedia
                          termasuk menyimpan limbah dalam wadah yang ditinggikan
                          dan / atau mengangkut limbah ke luar lokasi. <br />
                          Jika tidak mungkin membangun lubang di tanah yang
                          ditinggikan, lubang harus memiliki saluran drainase
                          yang dibangun di sekitarnya untuk mengarahkan air
                          menjauh. Lubang limbah tidak boleh diisi secara
                          berlebihan dan area di sekitarnya tetap bebas dari
                          limbah
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Lubang limbah dibangun untuk menahan banjir dan alternatif tersedia pada saat keadaan darurat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Lubang limbah dapat menahan banjir terbatas tetapi tidak ada cadangan atau alternatif yang ada "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Lubang limbah tidak tahan iklim dan tidak ada cadangan atau tidak ada lubang limbah."
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Disposal</b>
                    <p class="ma-0 color_txt small_txt">
                      Di mana pembakaran digunakan] Lubang abu khusus tersedia
                      untuk membuang abu dari pembakaran
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[45]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Abu dari pembakaran (yang meliputi logam berat /
                          dioksin dan furan) harus dibuang dengan aman di lubang
                          abu. <br />
                          Lubang abu: berjajar atau tidak bergaris tergantung
                          pada geologi tetapi harus mencegah pencucian ke
                          lingkungan, dengan lempengan, dasar lubang setidaknya
                          1,5 m dari meja air tanah. Jika air masuk ke lubang
                          abu, ia dapat melarutkan polutan ke dalam tanah.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Lubang abu ada dan berfungsi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Ada tetapi tidakberfungsi/ terlalu penuh atau tidak dipagari dan dikunci"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada lubang abu yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Disposal</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana kelahiran terjadi] Limbah anatomi/patologis
                      dimasukkan ke dalam lubang limbah patologis khusus,
                      dibakar di krematorium atau dikubur di kuburan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[46]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Lubang plasenta dapat dilapisi atau tidak bergaris
                          tergantung pada geologi tanah. Itu harus mencakup
                          lempengan. Lubang limbah harus ditutup (dengan
                          lempengan) untuk menghindari banjir dan tidak terlalu
                          penuh dan harus memiliki pipa ventilasi. Itu harus
                          dikunci atau dipagari untuk mencegah akses yang tidak
                          sah.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Lubang ada dan semua limbah anatomi / patologis dibuang dengan benar"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Pit ada tetapi tidak digunakan atau pit digunakan tetapi terlalu banyak diisi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada lubang yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Pharmaceutical waste</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Pharmaceutical waste</b>
                    <p class="ma-0 color_txt small_txt">
                      Limbah farmasi diolah dan dibuang dengan aman, baik di
                      fasilitas pengolahan dan pembuangan aman yang dikelola
                      secara terpusat (yaitu di luar lokasi), dengan mengirim
                      kembali ke pabrikan, atau dengan pembakaran oleh industri
                      yang menggunakan kiln suhu tinggi
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[47]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Strategi nasional dan/atau regional untuk
                          mengumpulkan dan mengolah/membuang limbah kimia dan
                          farmasi dari fasilitas kesehatan juga harus tersedia
                          dan beroperasi. Tanggung Jawab Produsen yang
                          Diperpanjang (EPR) untuk membawa obat-obatan dan bahan
                          kimia yang tidak digunakan atau kedaluwarsa kembali ke
                          produsen juga harus dipekerjakan. <br />
                          Insinerator terpusat harus mematuhi persyaratan
                          Konvensi Stockholm (untuk informasi lebih lanjut lihat
                          "Pedoman tentang BAT dan panduan sementara tentang
                          TARUHAN:
                          http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx).
                          <br />
                          Pembakaran bersama oleh industri semen: Investasi
                          tambahan yang signifikan dapat diperlukan untuk
                          memodifikasi fasilitas untuk penanganan dan pemuatan
                          limbah medis yang aman, dan mesin jarang dilengkapi
                          dengan peralatan penyaringan dan pembersihan yang
                          cocok untuk polutan yang dihasilkan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua limbah farmasi diolah dan dibuang dengan aman"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua limbah farmasi dibuang dengan benar"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Limbah farmasi tidak diolah atau dibuang dengan aman"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Personnel</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Seorang staf dilatih secara memadai untuk pengelolaan dan
                      pengawasan limbah perawatan kesehatan dan melaksanakan
                      tugas mereka dengan standar profesional yang sesuai
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[48]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Orang ini mungkin kepala perawat atau anggota komite
                          pengendalian pencegahan infeksi. Mereka harus
                          memastikan bahwa semua anggota staf bertanggung jawab
                          atas pemisahan dan pembuangan limbah yang dihasilkan
                          selama layanan mereka.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Seorang anggota staf dilatih secara memadai dan melaksanakan tugas dengan benar"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Seorang anggota staf dilatih tetapi tidak melaksanakan tugas dengan benar, atau ditunjuk tetapi tidak dilatih"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada anggota staf seperti itu yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Staf yang menangani atau membuang limbah dan petugas
                      perawatan kesehatan divaksinasi hepatitis B (dan memiliki
                      vaksinasi lain yang direkomendasikan, sesuai dengan
                      pedoman nasional)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[49]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Semua pekerja yang berisiko terpapar darah (termasuk
                          pembersih dan penjamah limbah) harus menerima
                          pra-layanan dan imunisasi berkelanjutan terhadap
                          hepatitis B (ketiga dosis) dan penyakit lain yang
                          dapat dicegah dengan vaksin di tempat kerja tanpa
                          biaya kepada anggota staf.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua staf telah menerima semua vaksinasi yang diperlukan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua staf telah divaksinasi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada staf yang telah divaksinasi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Emergency preparedness</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Emergency preparedness</b>
                    <p class="ma-0 color_txt small_txt">
                      [Ketika permintaan meningkat karena wabah atau peristiwa
                      terkait iklim] Strategi untuk menangani limbah tambahan
                      digunakan ketika permintaan meningkat
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[50]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Jumlah limbah yang lebih besar dapat dihasilkan
                          selama dan setelah peristiwa dan keadaan darurat
                          terkait iklim atau sebagai akibat dari wabah (misalnya
                          APD tambahan).<br />
                          Strategi meliputi:<br />
                          - Menyediakan tempat sampah tambahan <br />
                          - Frekuensi pengosongan kontainer yang lebih tinggi
                          <br />
                          - Area penyimpanan khusus untuk menyimpan limbah
                          ekstra yang dihasilkan
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Strategi untuk menangani limbah tambahan digunakan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Strategi ada tetapi tidak diterapkan secara efektif"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada strategi yang ada"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>

            <!-- ========================================STEP 4 ============================================= -->
            <v-stepper-content step="4" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Availability</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Availability</b>
                    <p class="ma-0 color_txt small_txt">
                      Sarana kebersihan tangan yang berfungsi tersedia di semua
                      titik perawatan, termasuk di ruang bersalin
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[51]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Kebersihan tangan adalah istilah umum yang mengacu
                          pada setiap tindakan pembersihan tangan, yaitu
                          tindakan melakukan kebersihan tangan untuk tujuan
                          menghilangkan kotoran, bahan organik, dan / atau
                          mikroorganisme secara fisik atau mekanis. Sumber:
                          Pedoman WHO tentang kebersihan tangan dalam perawatan
                          kesehatan. 2009
                          (https://www.who.int/gpsc/5may/tools/9789241597906/en/).
                          <br /><br />

                          Tempat kebersihan tangan fungsional dapat terdiri dari
                          sabun dan air dengan baskom/wajan untuk mencuci tangan
                          dan handuk sekali pakai atau bersih, atau handrub
                          berbasis alkohol (ABHR). <br /><br />

                          Titik perawatan adalah tempat di mana tiga elemen
                          bersatu: pasien, petugas perawatan kesehatan dan
                          perawatan atau perawatan yang melibatkan kontak dengan
                          pasien atau lingkungannya (dalam zona pasien). Di
                          beberapa fasilitas, petugas kesehatan membawa ABHR
                          berkeliling pada orang mereka saat mereka bergerak di
                          antara area layanan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua titik perawatan memiliki kebersihan tangan yang berfungsi (baik air dan sabun atau larutan penggosok tangan alkohol)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Setidaknya 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kurang dari 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Availability</b>
                    <p class="ma-0 color_txt small_txt">
                      Sarana kebersihan tangan yang berfungsi tersedia di semua
                      ruang tunggu dan area umum lainnya, dan di area pembuangan
                      limbah
                    </p>
                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[52]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Sarana kebersihan tangan fungsional: dapat terdiri
                          dari sabun dan air dengan baskom / wajan untuk mencuci
                          tangan dan handuk sekali pakai atau bersih atau
                          handrub berbasis alkohol.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sarana kebersihan tangan yang berfungsi tersedia di semua area"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sarana kebersihan tangan yang berfungsi tersedia di beberapa tetapi tidak semua area."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada Sarana kebersihan tangan yang berfungsi tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Availability</b>
                    <p class="ma-0 color_txt small_txt">
                      (Semua toilet memiliki tempat cuci tangan yang berfungsi
                      dalam jarak 5 meter)
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Refer to S_3_1"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Refer to S_3_2"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Refer to S_3_3"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== kepatuhan -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Hygiene promotion</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Hygiene promotion</b>
                    <p class="ma-0 color_txt small_txt">
                      Materi promosi kebersihan tangan ditampilkan dan terlihat
                      jelas di semua bangsal / area perawatan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[53]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Materi promosi kebersihan tangan menunjukkan
                          instruksi untuk kebersihan tangan (WHO 5 Moments for
                          hand hygiene) dan teknik yang benar
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Materi ditampilkan dengan jelas di semua bangsal / area perawatan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Materi ditampilkan dengan jelas di semua bangsal / area perawatan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada bahan yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Hygiene compliance</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Hygiene compliance</b>
                    <p class="ma-0 color_txt small_txt">
                      Kegiatan kepatuhan kebersihan tangan dilakukan secara
                      teratur (setidaknya setiap tahun)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[54]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pemantauan kebersihan tangan langsung berarti
                          pengamatan langsung terhadap kinerja sesuai dengan Who
                          5 Moments. Pemantauan kebersihan tangan langsung
                          berarti memantau konsumsi sabun dan alkohol berbasis
                          handrub. Untuk fasilitas yang lebih canggih, kerangka
                          penilaian diri kebersihan tangan WHO dapat
                          diselesaikan setiap tahun.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kegiatan kepatuhan rutin (setidaknya tahunan) berlangsung di seluruh fasilitas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kegiatan kepatuhan dalam kebijakan, tetapi tidak dilakukan dengan keteraturan apa pun"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada aktivitas kepatuhan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Supplies (hand hygiene)</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Supplies (hand hygiene)</b>
                    <p class="ma-0 color_txt small_txt">
                      Audit rutin (setidaknya setiap tiga bulan) berbasis
                      lingkungan dilakukan untuk menilai ketersediaan hand rub,
                      sabun, handuk sekali pakai, dan sumber daya kebersihan
                      tangan lainnya
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Audit berbasis lingkungan reguler (setidaknya tahunan) dilakukan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Audit berbasis lingkungan yang dilakukan kurang dari sekali seminggu atau audit tidak lengkap"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak dilakukan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>
            <!-- ========================================STEP 5 ============================================= -->
            <v-stepper-content step="5" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Policies</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Policies</b>
                    <p class="ma-0 color_txt small_txt">
                      Kebijakan atau protokol kebersihan fasyankes (atau
                      lingkungan) yang jelas dan terperinci dipajang dengan
                      jelas, diterapkan dan dipantau
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[55]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Kebijakan atau protokol harus mencakup hal-hal
                          berikut: <br />
                          • garis pelaporan fungsional yang ditentukan dan
                          tanggung jawab untuk semua staf yang terlibat,
                          termasuk staf tambahan (misalnya penangan limbah dan
                          pembersih);<br />
                          • jadwal pembersihan untuk setiap area perawatan
                          pasien dan peralatan perawatan pasien nonkritis,
                          menentukan frekuensi, metode, dan staf yang
                          bertanggung jawab;<br />
                          • rencana kontingensi dan prosedur pembersihan yang
                          diperlukan untuk manajemen wabah;<br />
                          • Persyaratan pelatihan dan standar kinerja untuk staf
                          kebersihan<br />
                          • metode pemantauan, frekuensi, dan staf yang
                          bertanggung jawab;<br />
                          • daftar produk pembersih, persediaan, dan peralatan
                          yang disetujui serta spesifikasi yang diperlukan untuk
                          penggunaannya; dan<br />
                          • daftar APD yang diperlukan dan kapan tindakan
                          kebersihan tangan direkomendasikan untuk staf
                          kebersihan.<br />
                          Bangsal khusus (misalnya ICU, unit ketergantungan
                          tinggi, ruang operasi) mungkin memerlukan protokol
                          khusus. <br /><br />

                          EC_1 sejalan dengan pertanyaan JMP G-C1. Mencoba
                          menilai apakah area layanan dianggap "bersih" sangat
                          subjektif, dan terlihat bersih mungkin sangat berbeda
                          dari bersih secara mikrobiologis. Demikian pula,
                          frekuensi pembersihan sulit diukur karena tidak dapat
                          diamati oleh enumerator dalam satu hari dan respons
                          cenderung tunduk pada bias responden. Seberapa sering
                          fasilitas perlu dibersihkan terkait dengan beban
                          pasien, oleh karena itu jadwal pembersihan sangat
                          bervariasi dari satu fasilitas ke fasilitas lainnya.
                          Menanyakan tentang adanya protokol pembersihan menjadi
                          indikasi pentingnya sebuah fasilitas terhadap
                          kebersihan lingkungan. Untuk pembersih melek huruf
                          rendah atau buta huruf, protokol harus disesuaikan dan
                          disederhanakan sesuai dengan gambar dan ilustrasi yang
                          dapat dikenali. .
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan atau protokol kebersihan ada, dilaksanakan dan dipantau"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan atau protokol kebersihan ada tetapi tidak diterapkan atau dipantau"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada kebijakan atau protokol kebersihan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== kepatuhan -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Monitoring</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Monitoring</b>
                    <p class="ma-0 color_txt small_txt">
                      Catatan pembersihan tersedia untuk area perawatan pasien,
                      bangsal umum atau seluruh fasilitas dan ditandatangani
                      oleh petugas kebersihan yang relevan setiap hari
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[56]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Buat catatan tersedia di lokasi pusat atau di mana
                          tugas pembersihan terjadi sehingga staf pengawas dapat
                          mengelolanya setiap hari, bersama dengan staf
                          (misalnya focal person IPC) yang bertanggung jawab
                          untuk kegiatan pemantauan berkala. Untuk panduan lebih
                          lanjut tentang catatan pembersihan dan mekanisme
                          pemantauan lainnya, lihat Praktik Terbaik CDC untuk
                          Pembersihan Lingkungan, bagian 2.4.3.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Tersedia di setiap bangsal/area atau seluruh fasilitas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Catatan ada, tetapi tidak untuk setiap lingkungan atau tidak untuk setiap hari atau sudah usang"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada catatan pembersihan yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Monitoring</b>
                    <p class="ma-0 color_txt small_txt">
                      Toilet dibersihkan setidaknya sekali setiap hari, dan
                      catatan pembersihan ditandatangani oleh petugas kebersihan
                      dan ditampilkan secara nyata
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[57]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Toilet pribadi harus dibersihkan dan didesinfeksi
                          setidaknya sekali sehari (misalnya per periode 24 jam)
                          atau ketika terlihat kotor, setelah pembersihan rutin
                          area perawatan pasien. <br />
                          Toilet umum atau bersama harus dibersihkan dan
                          didesinfeksi dua kali sehari, atau ketika terlihat
                          kotor.<br />
                          Untuk pembersih melek huruf rendah atau buta huruf,
                          catatan pembersihan harus disesuaikan dan
                          disederhanakan sesuai dengan gambar dan ilustrasi yang
                          dapat dikenali.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Toilet dibersihkan setiap hari dan catatan yang ditandatangani terlihat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toilet dibersihkan tetapi kurang dari sekali sehari dengan atau tanpa catatan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada catatan yang tersedia dan toilet dibersihkan kurang dari sekali sehari"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Personnel</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Jumlah staf kebersihan yang diperlukan atau staf dengan
                      tanggung jawab pembersihan tersedia di bangsal / fasilitas
                      setiap hari atau ketika pembersihan diperlukan dan
                      memiliki waktu yang didedikasikan untuk melakukan kegiatan
                      pembersihan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[58]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pembersih (juga dikenal sebagai staf kebersihan
                          lingkungan atau teknisi jasa lingkungan) adalah
                          individu yang bertanggung jawab untuk melakukan
                          pembersihan lingkungan di fasilitas perawatan
                          kesehatan yang memainkan peran kunci dalam menjaga
                          kebersihan dan / atau higienis lingkungan yang
                          memfasilitasi praktik yang terkait dengan pencegahan
                          dan pengendalian infeksi terkait perawatan
                          kesehatan.<br /><br />

                          Staf harus tersedia secara teratur (misalnya setiap
                          hari), cukup untuk semua bangsal dan dengan waktu
                          khusus untuk melakukan kegiatan pembersihan. Jumlah
                          staf kebersihan yang diperlukan akan bervariasi
                          berdasarkan beberapa faktor, termasuk: jumlah tempat
                          tidur pasien, tingkat hunian, jenis pembersihan
                          (misalnya, rutin atau terminal), jenis area perawatan
                          pasien (misalnya, area perawatan khusus seperti ICU
                          dan OR). Staf dapat paruh waktu atau penuh waktu.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Jumlah staf yang diperlukan tersedia setiap saat saat saat dibutuhkan dan memiliki waktu khusus untuk melakukan kegiatan pembersihan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa staf tersedia tetapi jumlahnya tidak mencukupi, tidak setiap saat bila diperlukan, atau tidak di semua lingkungan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada staf kebersihan yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua staf yang bertanggung jawab untuk kebersihan telah
                      menerima pelatihan tentang kebersihan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[59]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >"Staf yang bertanggung jawab untuk pembersihan"
                          mengacu pada penyedia layanan non-kesehatan seperti
                          pembersih, mantri atau staf tambahan, serta penyedia
                          layanan kesehatan yang, di samping tugas perawatan
                          klinis dan pasien mereka, melakukan tugas pembersihan
                          sebagai bagian dari peran mereka.<br />
                          Pelatihan mengacu pada rencana atau program pelatihan
                          terstruktur yang dipimpin oleh pelatih atau supervisor
                          yang berkualifikasi tepat.<br />
                          Staf harus menerima pelatihan penyegaran pra-layanan
                          dan tahunan, atau sebelum pengenalan persediaan atau
                          peralatan pembersih lingkungan baru. .
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua staf yang bertanggung jawab untuk pembersihan telah menerima pelatihan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua staf telah menerima pelatihan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada staf yang menerima pelatihan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Kebijakan dan praktik untuk meningkatkan keselamatan kerja
                      petugas kebersihan dan teknisi limbah perawatan kesehatan
                      tersedia dan diterapkan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[60]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Untuk informasi lebih lanjut tentang kesehatan kerja,
                          lihat: WHO/ILO (2014) HealthWISE - Peningkatan Kerja
                          dalam Layanan Kesehatan - Action Manual,
                          https://www.ilo.org/sector/Resources/training-materials/WCMS_237276/lang--en/index.htm
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tersedia dan diimplementasikan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tersedia tetapi tidak cukup diimplementasikan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada kebijakan yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Supplies</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Supplies</b>
                    <p class="ma-0 color_txt small_txt">
                      Bahan yang sesuai dan terawat dengan baik (misalnya
                      deterjen, pel, ember) untuk membersihkan berbagai area dan
                      permukaan yang berbeda tersedia dan cukup
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[61]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Minimal, kain pembersih sekali pakai atau dapat
                          digunakan kembali, ember, pel, deterjen, dan
                          desinfektan tingkat rendah. Produk harus sesuai dengan
                          panduan/peraturan tingkat nasional yang ada tentang
                          produk yang sesuai dan tersedia secara lokal untuk
                          pembersihan perawatan kesehatan. <br />
                          Persediaan rutin (misalnya bulanan) dan inspeksi
                          persediaan dan peralatan harus dilakukan untuk
                          mencegah kehabisan stok, mengantisipasi kebutuhan
                          pasokan dan memastikan ketersediaan bahan tambahan
                          untuk kontinjensi seperti wabah. <br /><br />

                          Daftar utama produk berkhasiat yang disetujui
                          fasilitas harus dikembangkan yang meminimalkan jumlah
                          produk berbahaya di sekitarnya. Banyak deterjen dan
                          desinfektan tradisional mengandung bahan kimia beracun
                          yang persisten yang dapat menyebabkan kanker, penyakit
                          pernapasan, iritasi mata dan kulit dan berkontribusi
                          terhadap pencemaran lingkungan selama pembuatan,
                          penggunaan, dan pembuangan. Fasilitas layanan
                          kesehatan harus mendapatkan dan menggunakan produk
                          pembersih yang tidak diberi pewangi dan lebih ramah
                          lingkungan, dengan kemasan minimal dan/atau ramah
                          lingkungan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua peralatan yang diperlukan tersedia, dalam kondisi baik dan cukup"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tersedia tetapi tidak terawat dengan baik atau di beberapa tetapi tidak semua area atau tidak cukup"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada bahan yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Supplies</b>
                    <p class="ma-0 color_txt small_txt">
                      Anggaran tahunan untuk perlengkapan dan peralatan
                      kebersihan lingkungan ada dan cukup untuk semua kebutuhan.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[62]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Anggaran tahunan untuk program pembersihan lingkungan
                          yang efektif, termasuk: <br />
                          • Personil (gaji dan tunjangan untuk staf kebersihan,
                          supervisor, dan manajer program secara keseluruhan)
                          <br />
                          • Pelatihan staf (setidaknya penyegaran pra-layanan
                          dan tahunan)<br />
                          • perlengkapan dan peralatan pembersih lingkungan,
                          termasuk APD untuk staf kebersihan<br />
                          • Biaya administrasi<br />
                          • Biaya produksi dan pencetakan untuk daftar periksa,
                          log, dan alat bantu pekerjaan lainnya<br />
                          • biaya infrastruktur/jasa, seperti layanan air
                          penunjang dan air limbah (sebagaimana berlaku)
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Anggaran ada dan cukup untuk semua kebutuhan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Anggaran ada tapi tidak cukup untuk semua kebutuhan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada anggaran"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Supplies</b>
                    <p class="ma-0 color_txt small_txt">
                      Area khusus untuk penyimpanan, persiapan dan perawatan
                      persediaan dan peralatan kebersihan ada ("area layanan
                      pembersihan lingkungan"), dijaga kebersihannya dan dirawat
                      dengan baik, dan digunakan sesuai dengan tujuannya
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[63]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Area layanan pembersihan lingkungan adalah ruang
                          khusus untuk mempersiapkan, memproses ulang, dan
                          menyimpan persediaan dan peralatan pembersih
                          lingkungan yang bersih atau baru, termasuk produk
                          pembersih dan APD. Kamar-kamar ini telah membatasi
                          akses hanya untuk staf kebersihan dan personel resmi
                          lainnya. Itu harus tersedia di dalam fasilitas,
                          terlepas dari apakah program pembersihan dikelola
                          sendiri atau oleh perusahaan eksternal. Area tersebut
                          harus berventilasi baik, diterangi, memiliki pasokan
                          air yang sesuai (akses air panas dan dingin, jika
                          memungkinkan), memiliki wastafel cuci tangan khusus,
                          APD tersedia, bebas dari kekacauan, dan berukuran
                          tepat. Untuk panduan lebih lanjut, lihat pedoman
                          Pembersihan Lingkungan CDC.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Area khusus ada, terawat dengan baik, dijaga kebersihannya dan digunakan sesuai dengan tujuannya"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Suatu area ada tetapi berisi barang-barang lain atau tidak bersih"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada area penyimpanan khusus"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">PPE</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>PPE</b>
                    <p class="ma-0 color_txt small_txt">
                      APD yang memadai tersedia setiap saat dan dalam jumlah
                      yang cukup untuk semua staf kebersihan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[64]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >APD minimum yang dibutuhkan untuk petugas kebersihan
                          di semua fasilitas adalah: 1. Gaun dan/atau celemek
                          plastik, 2. Sarung tangan karet yang dapat digunakan
                          kembali dan tahan lama, 3. Masker wajah, 4. kacamata
                          atau pelindung wajah. Semua APD (dapat digunakan
                          kembali dan sekali pakai) harus dalam persediaan yang
                          baik, terawat dengan baik (kualitas baik, stok
                          disimpan dengan tepat), dibersihkan sebelum digunakan,
                          dan dalam perbaikan yang baik. Semua APD yang dapat
                          digunakan kembali harus diproses ulang (yaitu,
                          dibersihkan dan didesinfeksi) setidaknya sekali
                          sehari. Sumber daya kebersihan tangan juga harus
                          tersedia untuk staf yang menggunakan APD.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua anggota staf kebersihan memiliki APD yang memadai"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua staf memiliki APD atau APD lengkap yang tersedia tetapi dalam kondisi buruk"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Emergency Preparedness</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Emergency Preparedness</b>
                    <p class="ma-0 color_txt small_txt">
                      [Jika beban pasien meningkat] Staf tambahan (misalnya
                      daftar nama) dan perlengkapan kebersihan tambahan tersedia
                      untuk ditempatkan di fasilitas jika beban pasien meningkat
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[65]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Lihat CDC Praktik terbaik untuk pembersihan
                          lingkungan dalam pengaturan terbatas sumber daya untuk
                          panduan lebih lanjut:
                          https://www.cdc.gov/hai/pdfs/resource-limited/environmental-cleaning-RLS-H.pdf
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Staf tambahan dan persediaan keduanya tersedia"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Baik staf atau persediaan tidak cukup untuk memenuhi kebutuhan tambahan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada staf atau persediaan tambahan yang tersedia."
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Laundry</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Laundry</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua tempat tidur/kasur memiliki penutup tahan air yang
                      tanpa tanda-tanda kerusakan (robek, sobek atau lubang)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[66]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Untuk perawatan rawat inap jangka panjang, penutup
                          tahan air harus dapat dilepas dan bernapas.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua tempat tidur/kasur memiliki penutup tahan air tanpa tanda-tanda kerusakan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tempat tidur / kasur memiliki penutup tahan air tetapi beberapa atau semua rusak"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada penutup tahan air"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Laundry</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasilitas binatu bersih, terawat dengan baik dan mampu
                      memenuhi permintaan (misalnya untuk mencuci linen dari
                      tempat tidur pasien di antara setiap pasien)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[67]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Area binatu harus memiliki drainase yang efektif dan
                          berendam dan harus ada area yang cukup untuk
                          mengeringkan linen dengan udara. Linen kotor harus
                          disimpan dalam kantong terpisah, tertutup, ditandai
                          untuk transportasi dan penyimpanan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Fasilitas binatu bersih, terawat dengan baik dan dapat memenuhi permintaan"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Fasilitas ada tetapi tidak bersih, terawat dengan baik atau mampu memenuhi permintaan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada fasilitas fungsional"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Laundry</b>
                    <p class="ma-0 color_txt small_txt">
                      Layanan binatu dengan air panas (70–80°C x 10 menit) untuk
                      memproses ulang kain dan kepala pel tersedia, dan kepala
                      pel serta kain pembersih selalu dicuci secara terpisah
                      dari tekstil rumah sakit kotor lainnya.
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Fasilitas binatu dengan air panas yang tersedia dan bahan pembersih dicuci secara terpisah"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Fasilitas binatu tersedia tetapi air tidak cukup suhu atau bahan pembersih tidak dicuci secara terpisah"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada layanan seperti itu yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Food Hygiene</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Food Hygiene</b>
                    <p class="ma-0 color_txt small_txt">
                      [Khusus rumah sakit] Makanan disiapkan dan ditangani
                      dengan aman (dengan tangan bersih, pada permukaan bersih
                      dan dengan peralatan bersih)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[68]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Permukaan yang digunakan untuk persiapan makanan
                          harus dicuci dengan deterjen dan air yang aman dan
                          kemudian dibilas, atau diseka dengan kain bersih yang
                          sering dicuci. Sisa-sisa makanan harus dibuang dengan
                          cepat, karena merupakan reservoir potensial bagi
                          bakteri dan dapat menarik serangga dan hewan pengerat.
                          Menolak harus disimpan di tempat sampah tertutup dan
                          dibuang dengan cepat dan aman. <br />
                          Peralatan makan harus dicuci segera setelah digunakan
                          dengan air panas dan deterjen, dan dikeringkan dengan
                          udara. <br />
                          Untuk panduan lebih lanjut, lihat WHO (2008) Standar
                          dan
                          https://www.who.int/foodsafety/publications/all/en/
                          kesehatan lingkungan yang penting
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Makanan disiapkan dan ditangani dengan aman"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua langkah keamanan pangan diikuti (lihat catatan)"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada langkah-langkah keamanan pangan yang diikuti / keamanan pangan sangat buruk"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Food Hygiene</b>
                    <p class="ma-0 color_txt small_txt">
                      [Khusus rumah sakit] Toko dapur dan makanan siap saji
                      dilindungi dari lalat, serangga dan tikus lainnya
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[69]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Area persiapan makanan harus dijaga kebersihannya dan
                          dilindungi dari hewan pengerat dan serangga.
                          Penyimpanan makanan harus ditutup untuk mencegah
                          kontaminasi oleh tikus dan lalat.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada lalat, serangga, atau tikus yang dapat mengakses toko makanan dan dapur"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toko makanan dan makanan sebagian dilindungi tetapi dapat ditingkatkan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Toko makanan dan makanan tidak memiliki perlindungan dari lalat, serangga, atau tikus"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>

            <!-- ========================================STEP 6 ============================================= -->
            <v-stepper-content step="6" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Energy</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Energy</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyankes memiliki sumber listrik yang fungsional dan
                      terawat dengan baik (misalnya jaringan listrik, surya)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[70]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Dalam pengaturan rawat inap yang terisolasi (seperti
                          rumah sakit pedesaan) dan dalam struktur sementara
                          (seperti pusat perawatan kolera), generator atau panel
                          surya dan baterai kemungkinan akan diperlukan dan
                          penyediaan untuk ini harus dilakukan. Minimal, jenis
                          minyak tanah atau lentera gas yang aman dan obor
                          tangan yang kuat harus tersedia.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sumber listrik ada, fungsional dan terawat dengan baik"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Ya, ada tetapi saat ini tidak berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada listrik"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Energy</b>
                    <p class="ma-0 color_txt small_txt">
                      Energi cukup untuk semua kebutuhan listrik fasilitas,
                      termasuk untuk penerangan dan perangkat yang berdiri
                      sendiri (misalnya Program yang Diperluas pada rantai
                      dingin Imunisasi)
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[71]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Semua kebutuhan mencakup pencahayaan, komunikasi,
                          peralatan/peralatan medis, dan perumahan staf.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Energi dalam jumlah yang cukup setiap saat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada energi yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Energy</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana air dipompa] Energi yang cukup tersedia untuk
                      memompa air
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[72]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Jika peningkatan ke sistem pemompaan diperlukan, ini
                          harus mempertimbangkan penggunaan energi terbarukan
                          (misalnya matahari)
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Energi dalam jumlah yang cukup setiap saat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada energi yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Energy</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di mana air dipanaskan] Energi yang cukup tersedia untuk
                      memanaskan air
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[73]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Jika peningkatan ke sistem pemanas diperlukan, ini
                          harus mempertimbangkan penggunaan energi terbarukan
                          (misalnya matahari)
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Energi dalam jumlah yang cukup setiap saat"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada energi yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ==============================================================================================================  -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Backup</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Backup</b>
                    <p class="ma-0 color_txt small_txt">
                      Sumber energi cadangan fungsional (misalnya generator
                      dengan bahan bakar yang memadai), ada jika sumber utama
                      gagal
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[74]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Sumber cadangan mungkin diperlukan untuk perangkat
                          medis, lemari es, penerangan, dan memompa air. Ini
                          harus menyala secara otomatis jika sumber daya rutin
                          terputus. Anggaran yang cukup harus tersedia untuk
                          bahan bakar untuk generator cadangan listrik. .
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ada sumber cadangan, dengan bahan bakar yang memadai"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sumber cadangan ada tetapi tidak berfungsi atau bahan bakar tidak mencukupi Beberapa tetapi tidak semua pencahayaan hemat energi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sumber cadangan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Efficiency</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Efficiency</b>
                    <p class="ma-0 color_txt small_txt">
                      Pencahayaan hemat energi digunakan dengan kontrol
                      pencahayaan yang ditingkatkan dan lampu hemat energi
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[75]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Cahaya alami mungkin cukup di siang hari dan harus
                          digunakan jika memungkinkan untuk mengurangi konsumsi
                          energi.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua pencahayaan hemat energi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua pencahayaan hemat energi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pencahayaan hemat energi yang tersedia atau statusnya tidak diketahui"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Adequacy</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Adequacy</b>
                    <p class="ma-0 color_txt small_txt">
                      Ruang bersalin cukup terang, termasuk di malam hari
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ruang bersalin memiliki pencahayaan yang berfungsi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Infrastruktur penerangan ada, tetapi tidak berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Adequacy</b>
                    <p class="ma-0 color_txt small_txt">
                      Kamar mandi cukup terang, termasuk di malam hari
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[76]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Penerangan untuk mandi diperlukan di semua fasilitas
                          di mana layanan malam hari disediakan dan di mana
                          tidak ada cahaya alami yang cukup untuk menggunakan
                          pancuran dengan aman di siang hari.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua kamar mandi  memiliki pencahayaan yang berfungsi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Alat penerangan ada, tetapi tidak berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak cukup penerangan atau tidak ada alat penerangan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Adequacy</b>
                    <p class="ma-0 color_txt small_txt">
                      Jamban cukup terang, termasuk di malam hari
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[77]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pencahayaan untuk jamban diperlukan di semua
                          fasilitas di mana layanan malam hari disediakan dan di
                          mana tidak ada cahaya alami yang cukup untuk
                          menggunakan jamban dengan aman di siang hari.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua jamban memiliki pencahayaan yang berfungsi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Infrastruktur penerangan ada, tetapi tidak berfungsi"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Ventilation</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Ventilation</b>
                    <p class="ma-0 color_txt small_txt">
                      Ventilasi lingkungan yang berfungsi cukup (alami atau
                      mekanis) tersedia di area perawatan pasien
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[78]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Bangunan harus ditempatkan dan dibangun menggunakan
                          desain dan bahan yang menghasilkan kondisi dalam
                          ruangan terbaik (misalnya jendela yang lebih besar,
                          overhang besar untuk naungan di iklim yang lebih
                          panas), dengan mempertimbangkan iklim lokal dan angin
                          yang berlaku. Bangunan dapat ditingkatkan dengan
                          penggunaan tirai yang efektif, membuka dan menutup
                          pintu dan jendela, penanaman vegetasi yang sesuai di
                          sekitar gedung dan langkah-langkah operasional lainnya
                          untuk membantu mengoptimalkan kondisi dalam ruangan.
                          Jika iklim memungkinkan, jendela terbuka besar,
                          skylight, dan ventilasi lainnya dapat digunakan untuk
                          mengoptimalkan ventilasi alami. Kipas langit-langit
                          dan ventilator portabel kecil tidak disarankan karena
                          mengeluarkan debu di sekitar ruangan (terutama untuk
                          area steril). Meningkatkan ventilasi mengurangi
                          ketergantungan pada AC. WHO 2009. Ventilasi alami
                          untuk pengendalian infeksi dalam pengaturan perawatan
                          kesehatan.
                          https://apps.who.int/iris/bitstream/handle/10665/44167/9789241547857_eng.pdf?sequence=1
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ventilasi cukup dan berfungsi di semua area pasien"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa ventilasi tersedia tetapi tidak terawat dengan baik atau tidak cukup untuk menghasilkan ventilasi udara alami"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada ventilasi"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Vector Control</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Vector Control</b>
                    <p class="ma-0 color_txt small_txt">
                      [Di daerah endemis malaria] Tempat tidur memiliki kelambu
                      yang dirawat insektisida untuk melindungi pasien dari
                      penyakit bawaan nyamuk
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[79]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Insecticide-treated nets should be washed and
                          re-impregnated every 6 months if used only for
                          patients with non-infectious diseases. For patients
                          with infectious diseases such as cholera, mosquito
                          nets are not advisable because the staff member needs
                          access to the patient. Other methods such as indoor
                          residual spraying or coils will be needed. Nets are
                          only provided exceptionally (patient request, patient
                          comfort, etc.) and burned after use.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua tempat tidur rawat inap memiliki jaring"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tersedia di beberapa, tetapi tidak semua tempat tidur, atau tersedia tetapi dalam kondisi buruk"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada kelambu yang tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Procurement</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Procurement</b>
                    <p class="ma-0 color_txt small_txt">
                      Pengadaan berkelanjutan (menggunakan pendekatan siklus
                      hidup) diterapkan di seluruh fasilitas
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[80]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Pengadaan berkelanjutan termasuk menggunakan produk
                          yang tersedia secara lokal yang telah disertifikasi
                          oleh lembaga sertifikasi terakreditasi dan yang sesuai
                          dengan standar internasional dipilih. (Produk tersebut
                          harus aman digunakan, memenuhi persyaratan toksisitas,
                          tahan lama, hemat energi dan sumber daya).
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Pengadaan berkelanjutan secara konsisten diterapkan di seluruh fasilitas"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Pendekatan pengadaan berkelanjutan ada tetapi tidak diterapkan dengan baik "
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pendekatan yang ada"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Environment</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Environment</b>
                    <p class="ma-0 color_txt small_txt">
                      Tempat sampah umum tersedia di semua area publik, sampah
                      secara teratur dikeluarkan dari interior dan eksterior
                      fasilitas, dan upaya dilakukan untuk meningkatkan dan
                      mempertahankan penampilan estetika fasilitas melalui
                      pengecatan, lansekap (tanaman) dan memastikan bahwa semua
                      peralatan dan barang-barang lainnya disimpan dengan aman
                    </p>
                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Upaya dilakukan untuk menjaga penampilan fasilitas secara keseluruhan yang rapi, bebas dari sampah dan terawat dengan baik"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa upaya dilakukan untuk menunjukkan fasilitas tetapi lebih banyak yang bisa dilakukan."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada upaya yang dilakukan untuk mempertahankan apperance fasilitas"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>

            <!-- ========================================STEP 7 ============================================= -->
            <v-stepper-content step="7" class="pa-0">
              <v-card class="pa-5" min-height="500px" flat>
                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Management</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Management</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyankes memiliki tim peningkatan kualitas yang
                      berfungsi/IPC atau WASH FIT
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[81]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Mungkin ada satu tim atau entitas terpisah untuk QI,
                          IPC dan WASH. <br />
                          Setidaknya satu anggota staf kebersihan/pengawas
                          kebersihan/perusahaan pembersih kontrak harus diwakili
                          dalam tim WASH FIT, IPC atau QI dan terlibat dalam
                          pengambilan keputusan/pengembangan rencana perbaikan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Tim ada, memiliki TOR yang jelas, bertemu secara teratur dengan kepemimpinan yang baik dan keputusan dicatat dan ditindaklanjuti"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tim bertemu tetapi secara tidak teratur, informal, tidak memiliki TOR yang jelas, dll."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada tim seperti itu dan/atau tidak ada titik fokus yang ada"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ==============================================================================================================  -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Personnel</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Fasyankes memiliki focal point atau tenaga teknis WASH
                      khusus yang bekerja untuk program kerja yang disetujui,
                      dengan dukungan pimpinan senior
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[82]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Titik fokus WASH/IPC harus bertanggung jawab atas
                          pemeliharaan WASH dan infrastruktur limbah perawatan
                          kesehatan. <br />
                          Di rumah sakit, juga harus ada focal person IPC khusus
                          selain focal point WASH. <br />
                          Kerangka Penilaian IPC WHO (IPCAF)
                          (https://www.who.int/infection-prevention/tools/core-components/IPCAF-facility.PDF)
                          dapat diselesaikan. IPCAF adalah alat sistematis yang
                          dapat memberikan penilaian dasar terhadap program dan
                          kegiatan IPC di dalam fasilitas perawatan kesehatan,
                          serta evaluasi berkelanjutan melalui administrasi
                          berulang untuk mendokumentasikan kemajuan dari waktu
                          ke waktu dan memfasilitasi perbaikan. Menambahkan
                          dukungan kepemimpinan
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Ada orang fokus yang berdedikasi"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Focal point ada tetapi tidak memiliki waktu, sumber daya atau motivasi yang cukup untuk melaksanakan tugas"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Management</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Management</b>
                    <p class="ma-0 color_txt small_txt">
                      Kelompok perempuan, disabilitas dan masyarakat adat, serta
                      pengguna dan staf khusus lainnya (misalnya perawat, bidan,
                      petugas kebersihan) dikonsultasikan tentang kebutuhan WASH
                      dan desain teknologi, dan suara-suara ini memengaruhi
                      pilihan, penempatan, dan pemeliharaan teknologi
                    </p>
                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Groups are adequately consulted and voices influence improvements"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Only some groups are consulted and/or voices do not influence improvements"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="None of the above are consulted "
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Management</b>
                    <p class="ma-0 color_txt small_txt">
                      Diagram terbaru dari struktur manajemen fasilitas,
                      termasuk staf kebersihan, terlihat jelas dan terbaca
                    </p>
                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Struktur manajemen fasilitas terkini ada (dan terbaca)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Struktur manajemen ada tetapi tidak up to date atau tidak terlihat"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak tersedia"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Management</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua staf tambahan, termasuk penangan limbah dan mereka
                      yang membersihkan, memiliki deskripsi pekerjaan yang jelas
                      dan tertulis, yang menguraikan tanggung jawab WASH dan IPC
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua staf memiliki deskripsi pekerjaan tertulis termasuk tanggung jawab WASH dan IPC"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa, tetapi tidak semua, staf memiliki deskripsi pekerjaan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada deskripsi pekerjaan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Personnel</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Semua staf tambahan baru, termasuk penangan limbah dan
                      mereka yang membersihkan, menerima pelatihan WASH dan IPC
                      yang sesuai, disesuaikan dengan fungsi pekerjaan mereka
                    </p>
                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Semua staf baru dilatih secara memadai, sesuai dengan fungsinya"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua staf dilatih atau pelatihan tidak sesuai dengan fungsinya"
                              color="#00ccb6"
                              value="2"
                            >
                            </v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada pelatihan yang berlangsung"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Personnel</b>
                    <p class="ma-0 color_txt small_txt">
                      Staf secara teratur (setidaknya setiap tahun) dinilai
                      berdasarkan kinerja mereka (misalnya kebersihan tangan);
                      staf berkinerja tinggi diakui dan / atau dihargai, dan
                      mereka yang tidak berkinerja baik didukung untuk
                      meningkatkan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[83]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Kinerja mungkin dalam kaitannya dengan kepatuhan
                          kebersihan tangan, serta kasih sayang, mengikuti
                          prosedur medis yang benar, mendengarkan pasien, dll.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Staf dinilai secara teratur (setidaknya setiap tahun)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Beberapa tetapi tidak semua staf dinilai atau staf tidak cukup didukung untuk meningkatkan"
                              color="#00ccb6"
                              value="2"
                            >
                            </v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada penilaian staf yang dilakukan, yaitu tidak ada tindakan atau pengakuan staf berdasarkan kinerja"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">SOPs</b>
                  </div>
                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>SOPs</b>
                    <p class="ma-0 color_txt small_txt">
                      Protokol dan sistem yang efektif tersedia untuk operasi
                      dan pemeliharaan infrastruktur yang berkelanjutan dan
                      pengadaan persediaan yang diperlukan untuk operasi dan
                      pemeliharaan
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[84]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Protokol ini mungkin terkait dengan pembersihan
                          lingkungan (disesuaikan untuk area layanan yang
                          berbeda), penanganan limbah dan operasi & pemeliharaan
                          pasokan air dan sanitasi. <br /><br />

                          Manajer program kebersihan, tim pengadaan fasilitas
                          dan/atau IPC fasilitas atau komite kebersihan harus
                          mengembangkan daftar induk persediaan dan peralatan
                          (yaitu spesifikasi terperinci dan informasi pemasok)
                          dan jumlah yang diperlukan (misalnya setiap tahun)
                          yang diperlukan. Hasil inspeksi rutin dan kegiatan
                          pemeliharaan harus menentukan jumlah persediaan dan
                          peralatan yang diperlukan. Fasilitas besar mungkin
                          memiliki toko pusat yang menerima persediaan dan
                          peralatan dan mendistribusikannya ke area layanan
                          pembersihan lingkungan yang ditunjuk di seluruh
                          fasilitas secara teratur setelah laporan inventaris.
                          Memiliki sistem seperti itu juga akan mencegah
                          kehabisan stok dan membantu memperoleh pasokan
                          tambahan sesuai kebutuhan selama keadaan darurat /
                          kontinjensi.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Sistem ada dan berfungsi (item diperoleh dan infrastruktur diperbaiki saat dan bila diperlukan)"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Sistem ada tetapi tidak berfungsi (yaitu fasilitas tidak dapat memperoleh pasokan atau infrastruktur tidak diperbaiki secara memadai)"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada sistem"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Budget</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Budget</b>
                    <p class="ma-0 color_txt small_txt">
                      Anggaran tersedia untuk menutupi biaya pembersih dan staf
                      pemeliharaan, pelatihan IPC/WASH, bahan habis pakai
                      IPC/WASH (misalnya sabun, klorin) dan semua kegiatan yang
                      tercantum dalam protokol pengadaan.
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[85]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Anggaran tersebut mencakup biaya modal dan
                          operasional, termasuk personel, pelatihan staf,
                          perlengkapan dan peralatan kebersihan, peralatan untuk
                          pemantauan program, biaya administrasi, biaya produksi
                          dan pencetakan untuk daftar periksa, log dan alat
                          bantu pekerjaan lainnya dan biaya
                          infrastruktur/layanan (misalnya layanan air dan air
                          limbah). Mungkin berguna untuk membagi anggaran ke
                          dalam kategori: personel; infrstruktur; peralatan;
                          Persediaan.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Anggaran ada dan ditujukan untuk staf/pelatihan dan bahan habis pakai/O&M"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Anggaran ada untuk staf tetapi tidak untuk pelatihan / atau untuk bahan habis pakai tetapi tidak untuk O&M 
/ atau anggaran tidak cukup untuk menutupi semua biaya."
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada anggaran"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Policies</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Policies</b>
                    <p class="ma-0 color_txt small_txt">
                      Kebijakan/piagam keselamatan pasien di seluruh Fasyankes
                      untuk meningkatkan kualitas perawatan ditulis, terkini,
                      dan operasional
                    </p>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tersedia, up-to-date dan operasional"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tidak operasional, atau perlu diperbarui/ tidak realistis"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada kebijakan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Policies </b>
                    <p class="ma-0 color_txt small_txt">
                      Kebijakan/piagam ramah lingkungan di seluruh Fasyankes
                      ditulis dan beroperasi
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[86]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Konsep ketahanan iklim harus diintegrasikan ke dalam
                          semua strategi dan rencana WASH fasilitas.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tertulis dan operasional"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Kebijakan tertulis tetapi tidak operasional"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada kebijakan"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>

                <!-- ============================================================================================================== -->
                <v-card class="pa-3 mb-4 rounded-max" outlined>
                  <div class="mb-3">
                    <b class="color_default">Emergency preparedness</b>
                  </div>

                  <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                    <b>Emergency preparedness</b>
                    <p class="ma-0 color_txt small_txt">
                      Rencana kesiapsiagaan dan tanggap darurat sudah ada,
                      dianggarkan dan diperbarui secara berkala; Staf menjalani
                      pelatihan dan latihan untuk mempersiapkan, menanggapi, dan
                      pulih dari peristiwa terkait cuaca ekstrem, terutama di
                      mana perubahan iklim merupakan faktor yang berkontribusi
                    </p>

                    <v-row>
                      <v-tooltip
                        right
                        v-model="showTooltip[87]"
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-row pa-2 mt-2 ml-1">
                            <div
                              class="d-flex pa-0 align-center"
                              elevation="0"
                              min-width="0"
                              height="0"
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <img
                                width="18"
                                height="18"
                                src="../../../assets/img/info-icon.svg"
                                alt=""
                              />
                            </div>
                            <span
                              class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                              >informasi</span
                            >
                          </div>
                        </template>
                        <span
                          >Rencana darurat diperbarui secara berkala dan
                          berulang berdasarkan informasi baru & data iklim dan
                          kerentanan. Sistem tersedia untuk menindaklanjuti
                          saran dan peringatan cuaca ekstrem untuk mengurangi
                          risiko kesehatan. Rencana tersebut harus mencakup
                          langkah-langkah untuk mendapatkan pasokan ketika
                          permintaan / beban pasien meningkat, terkait dengan
                          <br />
                          - sistem air (misalnya klorin, filter atau teknologi
                          pengolahan air lainnya rapid water testing kit)
                          <br />
                          - kebersihan tangan (sabun, gosok tangan berbasis
                          alkohol, stasiun kebersihan tangan, dll.) <br />
                          - pembersihan lingkungan (bahan kimia pembersih, pel,
                          ember, dll.). <br />
                          Pertimbangan lain termasuk ketersediaan tenaga
                          kesehatan siaga saat dibutuhkan, ketersediaan APD
                          ekstra, sistem untuk mengoperasikan fasilitas
                          kesehatan 24/7 selama keadaan darurat, atau persiapan
                          untuk mendukung selama COVID-19 seperti pandemi.
                        </span>
                      </v-tooltip>
                    </v-row>

                    <v-radio-group dense>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                            style="background: none !important"
                            class="pa-3 rounded-max"
                            height="100%"
                            outlined
                          >
                            <v-radio
                              label="Rencana sudah ada dan staf cukup terlatih"
                              color="#00ccb6"
                              value="1"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Rencana sudah ada tetapi bukan pelatihan yang dilakukan, atau rencana tidak realistis, atau tidak dilaksanakan"
                              color="#00ccb6"
                              value="2"
                            ></v-radio>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            class="pa-3 rounded-max"
                            height="100%"
                            style="background: none !important"
                            outlined
                          >
                            <v-radio
                              label="Tidak ada rencana"
                              color="#00ccb6"
                              value="3"
                            ></v-radio>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center">
            <v-btn
              color="white"
              dark
              class="pa-3 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('prev')"
              ><v-icon small color="#00b4cc"
                >mdi-arrow-left-circle-outline</v-icon
              >
              <p class="ma-0 color_default ml-2">Kembali</p></v-btn
            >
            <div class="mx-5">
              <p class="ma-0 color_default">{{ step }}/7</p>
            </div>
            <v-btn
              v-if="step != 7"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('next')"
            >
              <p class="ma-0 mr-2">Lanjut</p>
              <v-icon small>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="step == 7"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
            >
              <p class="ma-0 mr-2">lanjut</p>
              <v-icon small>mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "KeslingBiasa",
  computed: {
    ...mapState({
      form_data: (state) => state.formulir.kesling,
    }),
  },
  data() {
    return {
      showTooltip: Array(99).fill(false),
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
    };
  },

  methods: {
    switchStep(state) {
      if (state == "next") {
        if (this.step < 7) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // SET UPPP FORMMMMM
  },
};
</script>

<style scoped>
.v-radio {
  align-items: start !important;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 20%;
  border-radius: 10px 10px 0 0;
}
.mini_tab {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 6%;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ececec;
  border-bottom: none;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 20%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>