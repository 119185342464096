<template>
  <div>
    <div style="margin-top: 30px">
      <v-container class="pa-0 ma-0">
        <!-- Tab2 -->
        <div v-if="isiFormulir && tab == 2" class="">
          <div class="d-flex justify-center">
            <div style="position: relative">
              <div class="d-flex">
                <v-tabs
                  v-model="tabs"
                  background-color="transparent"
                  class="d-flex fasyankes w-100 m-0"
                >
                  <v-tab
                    href="#tabs1"
                    cols="3"
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">Data Limbah Padat</h3></v-tab
                  >
                  <v-tab
                    cols="3"
                    href="#tabs2"
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                  >
                    <h3 class="title-tabs">Sumber daya dan Organisasi</h3>
                  </v-tab>
                  <v-tab
                    cols="3"
                    href="#tabs3"
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">
                      Alur Pengelolaan limbah padat
                    </h3></v-tab
                  >
                  <v-tab
                    cols="3"
                    href="#tabs4"
                    class="text-capitalize header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">Pengolahan Limbah Cair</h3></v-tab
                  >
                </v-tabs>
              </div>
              <v-tabs-items v-model="tabs" style="background: white" class="">
                <!-- Tab 1 -->
                <v-tab-item value="tabs1">
                  <v-card class="mh-300 custom_card pa-5">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="soal-form">
                        <div class="questions">
                          <p>Apakah dilakukan pemilahan limbah domestik, medis tajam, dan medis infeksius pada wadah yang berbeda ?</p>
                        </div>
                        <div class="d-flex align-center">

                        </div>
                      </div>
                    </div>

                    <!-- IF Soal 1 = Yes -->
                    <div>
                      <!-- Soal 2 -->
                      <div class="border-soal">
                        <div class="title-soal-form">
                          <h6>Limbah Domestik</h6>
                        </div>
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah dilakukan pemilahan limbah domestik ?</p>
                          </div>
                          <div class="d-flex align-center">
     
                          </div>
                        </div>

                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Timbulan limbah organik</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                readonly
                                name="limbah-organik"
                                id="limbah-organik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/bulan</div>
                            </div>
                          </div>
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Timbulan limbah anorganik</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                readonly
                                name="limbahAnorganik"
                                id="limbahAnorganik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/bulan</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="added-form">
                            <div class="d-flex align-center per-form-added">
                              <label class="label-tipe-1" for="limbah-organik"
                                >Timbulan total limbah domestik</label
                              >
                              <div class="input-group">
                                <input
                                  type="number"
                                readonly
                                  name="limbah-organik"
                                  id="limbah-organik"
                                  class="form-control form-medium"
                                />
                                <div class="icon-group">kg/bulan</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Soal 3 -->
                      <!-- Limbah Medis -->
                      <div class="border-soal">
                        <div class="title-soal-form">
                          <h6>Limbah Medis</h6>
                        </div>
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah dilakukan pemilahan limbah medis ?</p>
                          </div>
   
                        </div>

                        <!-- IF Medis YA -->
                        <div>
                          <!-- Infeksius -->
                          <div class="added-form mt-4">
                            <div class="border-soal">
                              <div class="title-soal-form bg-next-title">
                                <h6>Limbah Infeksius</h6>
                              </div>
                              <div class="soal-form bors-sol">
                                <div class="questions">
                                  <p>Apakah dilakukan pemilahan limbah medis infeksius ?</p>
                                </div>
           
                              </div>

                              <!-- IF Infeksius Yes -->
                              <div class="added-form">
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahInfeksius"
                                    >Timbulan limbah infeksius</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                readonly
                                      name="rataTimbulanLimbahInfeksius"
                                      id="rataTimbulanLimbahInfeksius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahTajam"
                                    >Timbulan limbah tajam</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                readonly
                                      name="rataTimbulanLimbahTajam"
                                      id="rataTimbulanLimbahTajam"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahPatologi"
                                    >Timbulan limbah patologi</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      name="rataTimbulanLimbahPatologi"
                                readonly
                                      id="rataTimbulanLimbahPatologi"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                              </div>

                              <!-- IF Infeksius Tidak -->
                              <div class="added-form">
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="totalLimbahInfekius"
                                    >Timbulan total limbah medis infeksius</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                readonly
                                      name="totalLimbahInfekius"
                                      id="totalLimbahInfekius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Non Infeksius -->
                          <div class="added-form mt-4">
                            <div class="border-soal">
                              <div class="title-soal-form bg-next-title">
                                <h6>Limbah Non-Infeksius</h6>
                              </div>
                              <div class="soal-form bors-sol">
                                <div class="questions">
                                  <p>Apakah dilakukan pemilahan limbah medis non infeksius ?</p>
                                </div>
                      
                              </div>

                              <!-- IF Non-Infeksius Yes -->
                              <div class="added-form">
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahFarmasi"
                                    >Timbulan limbah farmasi</label
                                  >
                                  <div class="input-group">
                                    <input
                                readonly
                                      type="number"
                                      name="rataTimbulanLimbahFarmasi"
                                      id="rataTimbulanLimbahFarmasi"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahKimia"
                                    >Timbulan limbah kimia</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      name="rataTimbulanLimbahKimia"
                                readonly
                                      id="rataTimbulanLimbahKimia"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahSitotoksik"
                                    >Timbulan limbah sitotoksik</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      name="rataTimbulanLimbahSitotoksik"
                                      id="rataTimbulanLimbahSitotoksik"
                                readonly
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahRadioAktif"
                                    >Timbulan limbah radioaktif</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      name="rataTimbulanLimbahRadioAktif"
                                readonly
                                      id="rataTimbulanLimbahRadioAktif"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                              </div>

                              <!-- IF Non-Infeksius Tidak -->
                              <div class="added-form">
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="totalLimbahNoninfekius"
                                    >Timbulan total limbah medis non infeksius</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      name="totalLimbahNoninfekius"
                                readonly
                                      id="totalLimbahNoninfekius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/bulan</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- V Else Soal 0 = Tidak -->
                    <div>
                      <div class="border-soal">
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Jumlah Total Limbah</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                name="limbah-organik"
                                readonly
                                id="limbah-organik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/bulan</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 2 -->
                <v-tab-item value="tabs2">
                  <v-card class="mh-300 custom_card pa-5">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Unit Khusus / Organisasi Kesehatan Lingkungan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat unit yang bertanggung jawab terhadap kesehatan lingkungan ?
                          </p>
                        </div>
                   
                      </div>
                    </div>

                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Petugas Kesehatan Lingkungan</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa jumlah petugas kesehatan lingkungan ?</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Orang</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 3 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Petugas Pengelola Limbah B3</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Berapa jumlah petugas pengelola limbah ?</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Orang</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Alokasi Dana / Anggaran (Tahunan)</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Berapa jumlah dana atau anggaran pengelolaan limbah dalam setahun</label
                          >
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                                readonly
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Berapa jumlah dana atau anggaran kesehatan lingkungan dalam setahun</label
                          >
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                                readonly
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Berapa jumlah dana atau anggaran keseluruhan Fasyankes dalam setahun
                          </label>
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Dokumen Izin Lingkungan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat dokumen izin lingkungan ?</p>
                        </div>
                    
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              name="totalLimbahNoninfekius"
                                readonly
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                                readonly
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 6 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>
                          Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah
                          Medis/B3
                        </h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat kebijakan tertulis (SE/SK) mengenai pengelolaan limbah Fasyankes ?</p>
                        </div>
                     
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              name="totalLimbahNoninfekius"
                                readonly
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                                readonly
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 7 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Prosedur Pengelolaan Limbah (SOP)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat prosedur pengelolaan limbah (SOP) ?
                          </p>
                        </div>
                
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              name="totalLimbahNoninfekius"
                                readonly
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                                readonly
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 8 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Wadah</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas wadah memadai ?</p>
                        </div>
                  
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Ya :</h6>
                            <p>
                              Wadah limbah tersedia di semua tempat penghasil limbah dengan jumlah dan kapasitas wadah yang memadai
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 9 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Alat Angkut</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas alat angkut memadai ?</p>
                        </div>
                    
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Ya :</h6>
                            <p>Alat angkut limbah tersedia dengan jumlah dan kapasitas yang memadai untuk mengumpulkan limbah dari sumber ke penyimpanan</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 10 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Alat Pelindung Diri (APD)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas Alat Pelindung Diri (APD) memadai ?</p>
                        </div>
                    
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Ya :</h6>
                            <p>APD tersedia dengan jenis lengkap serta jumlah dan ukuran yang memadai untuk digunakan petugas</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 11 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Timbangan Medis</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat timbangan limbah medis yang dapat digunakan ?</p>
                        </div>
                   
                      </div>
                    </div>

                    <!-- Soal 12 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Pencatatan Limbah Medis (Log Book)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat pencatatan timbulan limbah medis (log book) ?
                          </p>
                        </div>
                   
                      </div>
                    </div>

                    <!-- Soal 13 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Cold storage / Freezer Khusus Limbah Medis</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat cold storage/freezer limbah infeksius yang dapat digunakan ?
                          </p>
                        </div>
                  
                      </div>
                    </div>

                    <!-- Soal 14 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Kapasitas Cold Storage / Freezer</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah Kapasitas cold storage / freezer
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">m³</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 3 -->
                <v-tab-item value="tabs3">
                  <v-card class="mh-300 custom_card pa-5">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Pewadahan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah tersedia plastik warna hitam untuk limbah domestik ?
                          </p>
                        </div>
                       
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah tersedia plastik warna kuning untuk limbah medis infeksius dan patologi ?
                          </p>
                        </div>
                  
                      </div>

                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia safety box untuk limbah tajam ?</p>
                        </div>
                  
                      </div>

                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah tersedia plastik warna ungu untuk limbah sitotoksik ?
                          </p>
                        </div>
                     
                      </div>

                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah tersedia plastik warna merah untuk limbah radioaktif ?
                          </p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia plastik warna cokelat untuk limbah farmasi ?</p>
                        </div>
                       
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia plastik warna cokelat untuk limbah kimia ?</p>
                        </div>
                       
                      </div>
                    </div>


                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Apakah pewadahan limbah medis memiliki kriteria sebagai berikut :</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Tertutup</p>
                        </div>
                       
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak Bocor</p>
                        </div>
                      
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Label Sesuai</p>
                        </div>
                 
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Simbol sesuai</p>
                        </div>
                    
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Mudah dibersihkan</p>
                        </div>
                      
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Warna sesuai</p>
                        </div>
                       
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Apakah pengangkutan/pengumpulan limbah medis memiliki kriteria sebagai berikut :</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Alat angkut khusus limbah</p>
                        </div>
                    
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Wheel bin/wadah beroda</p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tertutup</p>
                        </div>
               
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Jalur khusus</p>
                        </div>
             
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Waktu khusus</p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak bocor</p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Label sesuai</p>
                        </div>
              
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Simbol sesuai</p>
                        </div>
                  
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Mudah dibersihkan</p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Warna sesuai</p>
                        </div>
                    
                      </div>
                    </div>

                    <!-- Soal 6 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Dimana penyimpanan limbah medis dilakukan ?</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Di Tempat Penyimpanan Sementara (TPS) limbah B3 berizin (di dalam area Fasyankes)
                          </p>
                        </div>
               
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (di luar area Fasyankes)
                          </p>
                        </div>
                     
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Di tempat lainnya (gudang, ruang kosong, ruang terbuka, dll)
                          </p>
                        </div>
         
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak ada tempat penyimpanan</p>
                        </div>
                     
                      </div>
                    </div>

                    <!-- Soal 7 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>
                          Pengangkutan
                        </h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia kendaraan pengangkut limbah roda tiga ?</p>
                        </div>
                        
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia kendaraan pengangkut limbah roda empat ?</p>
                        </div>
               
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah tersedia kendaraan pengangkut limbah dari perusahaan pengelola limbah ?</p>
                        </div>
                    
                      </div>
                    </div>

                    <!-- Soal 8 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Ketersediaan Alat Pengolah Limbah Di Fasyankes</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat insinerator yang berfungsi sebagai alat pengolah limbah ?
                          </p>
                        </div>

                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Kapasitas insinerator :
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                                readonly
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Kg/jam</div>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat autoclave/microwave yang berfungsi sebagai alat pengolah limbah ?
                          </p>
                        </div>
                 
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Kapasitas autoclave/microwave :
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              name="jumlahPetugasPengelolaLimbah"
                                readonly
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Kg/jam</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 9 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Spesifikasi pengolahan limbah</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah ingin menggunakan data sebelumnya ?</p>
                        </div>

                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah terdapat insinerator berizin sebagai alat pengolah limbah ?</p>
                        </div>

                   
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                                readonly
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal izin
                            </div>
                            <v-menu
                              v-model="calendar1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              disabled
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar1 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat autoclave/microwave berizin sebagai alat pengolah limbah ?
                          </p>
                        </div>
                
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              name="totalLimbahNoninfekius"
                                readonly
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal izin
                            </div>
                            <v-menu
                              v-model="calendar2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              disabled
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar2 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah Fasyankes melakukan kerja sama dengan pengolah limbah medis berizin ?</p>
                        </div>

                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <input
                              placeholder="Nama Perusahaan pengolah limbah sesuai dengan PKS"
                              name="totalLimbahNoninfekius"
                                readonly
                              id="totalLimbahNoninfekius"
                              class="form-control long_input"
                            />
                          </div>
                          <div class="px-3"></div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                                readonly
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal PKS
                            </div>
                            <v-menu
                              v-model="calendar3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              disabled
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar3 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Apakah limbah medis diolah dengan metode lainnya ?</p>
                        </div>

                     
                      </div>
                    </div>
                    <!-- Soal 10 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Pengolahan limbah dengan metode lainnya</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Disinfeksi untuk limbah infeksius padat</p>
                        </div>
                  
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Inertifikasi / solidifikasi untuk limbah farmasi
                            dan kimia padat
                          </p>
                        </div>
                 
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Enkapsulasi untuk limbah tajam</p>
                        </div>
                        
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Penguburan limbah patologi</p>
                        </div>
               
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 4 -->
                <v-tab-item value="tabs4">
                  <v-card class="mh-300 custom_card pa-5">

                    <!-- Soal 3 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Apakah terdapat instalasi pengolahan air limbah (IPAL)</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah terdapat Instalasi pengelolaan air limbah
                                (IPAL)
                            </p>
                          </div>
                          <div class="d-flex align-center">
                      
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Jumlah kapasitas IPAL
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">m<sup>3</sup></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Debit</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata debit air masuk ke IPAL</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Liter/hari</div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata debit air keluar dari IPAL</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Liter/hari</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Kualitas air Limbah</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata pH harian air limbah ?
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                                readonly
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">1-4</div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata suhu harian air limbah ?</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              name="jumlahPetugasKesehatanLingkungan"
                                readonly
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">℃</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    

                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Izin pembuangan limbah cair (IPLC)</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah Fasyankes memiliki izin pembuangan limbah cair (IPLC) atau persetujuan teknis IPAL ?
                            </p>
                          </div>
                          <div class="d-flex align-center">

                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">No</div>
                              <input
                                type="number"
                                readonly
                                name="totalLimbahNoninfekius"
                                id="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              />
                            </div>
                            <div class="px-3">-</div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar4"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                disabled
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="form-control form-medium"
                                  />
                                </template>
                                <v-date-picker
                                  @input="calendar4 = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-icon class="ml-2">mdi-calendar</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Baku mutu limbah cair</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah hasil uji baku mutu limbah cair sesuai dengan standar ?</p>
                          </div>
                          <div class="d-flex align-center">
            
                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">No</div>
                              <input
                                type="number"
                                name="totalLimbahNoninfekius"
                                readonly
                                id="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              />
                            </div>
                            <div class="px-3">-</div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar5"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                disabled
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="form-control form-medium"
                                  />
                                </template>
                                <v-date-picker
                                  @input="calendar5 = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-icon class="ml-2">mdi-calendar</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>

          <div class="paginat">
            <div class="button-pag">
              <div>
                <v-btn
                  color="white"
                  dark
                  class="pa-3 custom_shadow rounded-lg text-capitalize"
                  @click="tabKembali"
                  ><v-icon small color="#00b4cc"
                    >mdi-arrow-left-circle-outline</v-icon
                  >
                  <p class="ma-0 color_default ml-2">Kembali</p></v-btn
                >
              </div>
              <div>
                <p class="ma-0 color_default">{{ paginNow }} / 4</p>
              </div>

              <div>
                <v-btn
                  v-if="tabs != 'tabs4'"
                  min-width="10px"
                  color="#00b4cc"
                  dark
                  class="pa-2 custom_shadow rounded-lg text-capitalize"
                  @click="tabLanjut"
                >
                  <p class="ma-0 mr-2">Lanjut</p>
                  <v-icon small>mdi-arrow-right-circle-outline</v-icon>
                </v-btn>

                <v-btn
                  v-if="tabs == 'tabs4'"
                  min-width="10px"
                  color="#00b4cc"
                  dark
                  class="pa-2 custom_shadow rounded-lg text-capitalize"
                >
                  <p class="ma-0 mr-2">Lanjut</p>
                  <v-icon small>mdi-tray-arrow-up</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fasyankes",
  computed: {},
  data() {
    return {
      loading: false,
      isiFormulir: true,
      tab: 2,
      tabs: "tabs1",
      menu: false,
      role: "",
      users: false,
      menuLimit: false,
      paginNow: 1,
      formulir: {
        bor: 1,
        pemilahanDataLimbahPadat: null,
        pemilihanLimbahDomestik: null,
        limbahOrganik: null,
        limbahAnorganik: null,
        totalLimbahDomestik: null,
        limbahmedis: null,
        noninfeksius: null,
        rataTimbulanLimbahInfeksius: null,
        rataTimbulanLimbahTajam: null,
        rataTimbulanLimbahPatologi: null,
        totalLimbahInfekius: null,
        rataTimbulanLimbahFarmasi: null,
        rataTimbulanLimbahKimia: null,
        rataTimbulanLimbahSitotoksik: null,
        rataTimbulanLimbahRadioAktif: null,
        totalLimbahNoninfekius: null,
        KetersediaanUnit: null,
      },
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      calendar4: false,
      calendar5: false,
    };
  },

  methods: {
    tabLanjut() {
      if (this.tabs == "tabs1") {
        this.tabs = "tabs2";
        this.paginNow++;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs3";
        this.paginNow++;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs4";
        this.paginNow++;
      }
    },
    tabKembali() {
      if (this.tabs == "tabs4") {
        this.tabs = "tabs3";
        this.paginNow--;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs2";
        this.paginNow--;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs1";
        this.paginNow--;
      }
    },
  },
};
</script>

<style scoped>
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.long_input {
  width: 250px;
}
.long_input:placeholder {
  font-size: small;
}
.long_input:focus {
  outline: none;
}
</style>