<template>
  <div class=" pb-5">
    <div class="d-flex justify-center">
      <div style="margin-top: 30px; " class="pb-5">
        <div class="temp_itemtab">
          <v-stepper
            v-model="step"
            elevation="0"
            flat
            class="temp_stepper_header"
          >
            <div class="d-flex">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center">
                  Sumber Limbah PadatCovid-19
                </p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center">
                  Data Limbah Padat Covid-19
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengolahan Limbah Padat Covid-19
                </p>
              </div>
              <div
                :class="
                  step == 4 ? 'active_tab px-3 py-5 ' : 'header_tabs px-3 py-5'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengelolaan Limbah Cair Covid-19
                </p>
              </div>
            </div>

            <v-stepper-items>
              <!-- TAB 1 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="1" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Jumlah Pasien Covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Rata-rata jumlah pasien covid-19 di Fasyankes
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">Orang/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Rata-rata jumlah pasien covid-19 di Isolasi
                            Terkendali (Isoter)
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" 
                          disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">Orang/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 2 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="2" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan limbah domestik covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah domestik
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah medis Infeksius covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Infeksius
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Tajam
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Farmasi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Patologi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Kimia
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah Isolasi Terpusat (Isoter)</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah infeksius
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah Vaksinasi Covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah vaksinasi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 3 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="3" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <v-row>
                      <v-col cols="9">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Apakah mau menggunakan data yang sama dengan data
                            pengolahan limbah medis fasyankes?
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="3">

                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pengolah Limbah Di Fasyankes</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah insinerator
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
          
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="mb-3">
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Kapasitas Operasional:
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">Kg/jam</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah
                              <i>autoclave/microwave</i>
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                  
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="mb-3">
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Kapasitas Operasional:
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input type="number" disabled class="pa-2 mr-1 custom_input" />
                          <p class="ma-0 small_txt color_txt">Kg/jam</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <!-- -------------------------------------------------------SPESIFIKASI LIMBAH ----------------------------------------------------------->

                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Spesifikasi pengolahan limbah</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Diolah dengan insinerator
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                  
                        </v-col>
                      </v-row>
                    </v-card>
                    <div class="d-flex align-center mb-4">
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <p class="ma-0 small_txt color_txt mr-2">No</p>
                        <input type="number" disabled class="pa-2 custom_input" />
                      </v-card>
                      <div class="mx-3" style="width: 20px">
                        <v-divider></v-divider>
                      </div>
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <div class="marlef">
                          <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                        </div>

                        <v-menu
                          v-model="calendar1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              type="text"
                              placeholder="mm/dd/yyyy"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="pa-2 custom_date"
                            />
                          </template>
                          <v-date-picker
                            @input="calendar1 = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-icon>mdi-calendar</v-icon>
                      </v-card>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah
                              <i>autoclave/microwave</i>
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
            
                        </v-col>
                      </v-row>
                    </v-card>
                    <div class="d-flex align-center mb-4">
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <p class="ma-0 small_txt color_txt mr-2">No</p>
                        <input type="number" disabled class="pa-2 custom_input" />
                      </v-card>
                      <div class="mx-3" style="width: 20px">
                        <v-divider></v-divider>
                      </div>
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <div class="marlef">
                          <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                        </div>
                        <v-menu
                          v-model="calendar2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              type="text"
                              placeholder="mm/dd/yyyy"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="pa-2 custom_date"
                            />
                          </template>
                          <v-date-picker
                            @input="calendar2 = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-icon>mdi-calendar</v-icon>
                      </v-card>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Bekerja sama dengan pengolah limbah medis
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                  
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row>
                      <v-col cols="6">
                        <v-card class="d-flex align-center pl-2 mr-5" outlined>
                          <input
                            type="text"
                            placeholder="Nama Perusahaan pengelola limbah sesuai dengan PKS"
                            class="pa-2 custom_long_input"
                          />
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex align-center mb-4">
                          <v-card
                            class="d-flex align-center pl-2"
                            outlined
                            style="width: max-content"
                          >
                            <p class="ma-0 small_txt color_txt mr-2">No</p>
                            <input type="number" disabled class="pa-2 custom_input" />
                          </v-card>
                          <div class="mx-3" style="width: 20px">
                            <v-divider></v-divider>
                          </div>
                          <v-card
                            class="d-flex align-center pl-2"
                            outlined
                            style="width: max-content"
                          >
                            <div class="marlef">
                              <p class="ma-0 small_txt color_txt">
                                Tanggal Izin
                              </p>
                            </div>
                            <v-menu
                              v-model="calendar3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="pa-2 custom_date"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar3 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon>mdi-calendar</v-icon>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Diolah dengan metode lainnya
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
               
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>

                  <!-- ------------------------------------------------------------------------------------------------------------------------------- -->
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Pengolahan limbah metode lainnya</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Disinfeksi untuk limbah infeksius padat
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                    
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Inertifikasi / solidifikasi untuk limbah farmasi
                              dan kimia padat
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                    
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Enkapsulasi untuk limbah tajam
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                    
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Penguburan limbah patologi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                  
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
              <!-- TAB 4 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="4" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Metode pengolahan limbah cair</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Klorinasi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                 
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Ultraviolet
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Ozon
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center">
            <v-btn
              color="white"
              dark
              class="pa-3 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('prev')"
              ><v-icon small color="#00b4cc"
                >mdi-arrow-left-circle-outline</v-icon
              >
              <p class="ma-0 color_default ml-2">Kembali</p></v-btn
            >
            <div class="mx-5">
              <p class="ma-0 color_default">{{ step }}/4</p>
            </div>
            <v-btn
              v-if="step != 4"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('next')"
            >
              <p class="ma-0 mr-2">Lanjut</p>
              <v-icon small>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="step == 4"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
            >
              <p class="ma-0 mr-2">lanjut</p>
              <v-icon small>mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </div>
        <div>
       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      formulir_covid: (state) => state.formulir.get_formulir_covid,
    }),
  },
  name: "formulirLimbahcoivd",
  data() {
    return {
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
    };
  },
  mounted() {},
  methods: {
    switchStep(state) {
      if (state == "next") {
        if (this.step < 4) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // update data

    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>